import { styled } from '@mui/material/styles';
import { TextField as MuiTextField } from '@mui/material';

import palette from '../../theme/palette';

export const StyledTextField = styled(MuiTextField)({
  width: '100%',
  padding: '4px',
  '& .MuiInputBase-root': {
    padding: '12px 24px 12px 24px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'solid 1px',
    borderColor: palette.colors.gray03,
  },
  '& .MuiOutlinedInput-input': {
    fontWeight: 500,
    color: palette.colors.gray06,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
  // When disabled keep the style
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
    fontWeight: 500,
    color: palette.colors.gray06,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.colors.gray03,
    },
  },
});
