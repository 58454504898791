import React from 'react';
import { 
  CustomButtonProps,
  StyledPaddingButton,
  StyledLargeButton,
  StyledLargeRadiusButton 
} from './Button.styled';

/**
 * @interface ButtonProps
 * @property {CustomButtonProps['customVariant']} [customVariant] - The custom variant for the button styling (e.g., 'contained-blue', 'outlined-red').
 * @property {React.ReactNode} children - The content of the button.
 * @property {() => void} [onClick] - Callback function to handle the button click.
 * 
 * Omit<React.ComponentProps<typeof StyledPaddingButton>, 'customVariant'> ensures that all props that the MUI Button would accept are available in ButtonProps,
 *  except for customVariant, which is handled separately by each StyledButton interface variant.
 */
interface BaseButtonProps extends Omit<React.ComponentProps<typeof StyledPaddingButton>, 'customVariant'> {
  customVariant?: CustomButtonProps['customVariant'];
  children: React.ReactNode;
}

export const PaddingButton: React.FC<BaseButtonProps> = ({ customVariant = 'contained-blue', children, onClick, ...props }) => (
  <StyledPaddingButton customVariant={customVariant} onClick={onClick} {...props}>
    {children}
  </StyledPaddingButton>
);

export const LargeButton: React.FC<BaseButtonProps> = ({ customVariant = 'contained-blue', children, onClick, ...props }) => (
  <StyledLargeButton customVariant={customVariant} onClick={onClick} {...props}>
    {children}
  </StyledLargeButton>
);

export const LargeRadiusButton: React.FC<BaseButtonProps> = ({ customVariant = 'contained-blue', children, onClick, ...props }) => (
  <StyledLargeRadiusButton customVariant={customVariant} onClick={onClick} {...props}>
    {children}
  </StyledLargeRadiusButton>
);
