import { gql } from '@apollo/client';


export const USER_QUERY = gql`
  {
    user {
      id
      uuid
      isSuperuser
      allowSwitchRolesOnFrontend
      email
      firstName
      lastName
      timezone
      avatar
      accountFullLogo
      accountAvatarLogo
    }
  }
`;
