/* eslint-disable no-unused-vars */
import React, {
    createContext,
    ReactNode,
    useMemo,
    useState,
  } from 'react';
  
export type CallTabType = 'unclaimed' | 'claimed' | 'completed';

type AppContextType = {
  refetchClaimedCalls?: () => void;
  setRefetchClaimedCalls: (refetch: () => void) => void;
  refetchCompletedCalls?: () => void;
  setRefetchCompletedCalls: (refetch: () => void) => void;
  refetchUnclaimedCalls?: () => void;
  setRefetchUnclaimedCalls: (refetch: () => void) => void;
  refetchCall?: () => void;
  setRefetchCall: (refetch: () => void) => void;
  callsSelectedTab: CallTabType;
  setCallsSelectedTabName: (tab: CallTabType) => void;
};

type AppContextProviderProps = {
  children: ReactNode;
};

export const AppContext = createContext<AppContextType>({
  refetchClaimedCalls: undefined,
  setRefetchClaimedCalls: () => {},
  refetchCompletedCalls: undefined,
  setRefetchCompletedCalls: () => {},
  refetchUnclaimedCalls: undefined,
  setRefetchUnclaimedCalls: () => {},
  refetchCall: undefined,
  setRefetchCall: () => {},
  callsSelectedTab: 'unclaimed',
  setCallsSelectedTabName: () => {},
});

const AppProvider: React.FC<AppContextProviderProps> = ({ children }) => {
  const [refetchClaimedCalls, setRefetchClaimedCalls] = useState<() => void>();
  const [refetchCompletedCalls, setRefetchCompletedCalls] = useState<() => void>();
  const [refetchUnclaimedCalls, setRefetchUnclaimedCalls] = useState<() => void>();
  const [refetchCall, setRefetchCall] = useState<() => void>();
  const [refetchMyTopics, setRefetchMyTopics] = useState<() => void>();
  const [refetchCurrentTopic, setRefetchCurrentTopic] = useState<() => void>();
  const [callsSelectedTab, setCallsSelectedTab] = useState<CallTabType>('unclaimed');

  const providerSetRefetchClaimedCalls = (newRefetch: () => void) => {
    setRefetchClaimedCalls(() => newRefetch);
  };

  const providerSetRefetchCompletedCalls = (newRefetch: () => void) => {
    setRefetchCompletedCalls(() => newRefetch);
  };

  const providerSetRefetchUnclaimedCalls = (newRefetch: () => void) => {
    setRefetchUnclaimedCalls(() => newRefetch);
  };

  const providerSetRefetchCall = (newRefetch: () => void) => {
    setRefetchCall(() => newRefetch);
  };

  const providerSetRefetchMyTopics = (newRefetch: () => void) => {
    setRefetchMyTopics(() => newRefetch);
  };

  const providerSetRefetchCurrentTopic = (newRefetch: () => void) => {
    setRefetchCurrentTopic(() => newRefetch);
  };

  const providerSetSelectedTabName = (tabName: CallTabType) => {
    setCallsSelectedTab(tabName);
  };

  const value = useMemo(() => ({
    refetchClaimedCalls,
    setRefetchClaimedCalls: providerSetRefetchClaimedCalls,
    refetchCompletedCalls,
    setRefetchCompletedCalls: providerSetRefetchCompletedCalls,
    refetchUnclaimedCalls,
    setRefetchUnclaimedCalls: providerSetRefetchUnclaimedCalls,
    refetchCall,
    setRefetchCall: providerSetRefetchCall,
    setRefetchMyTopics: providerSetRefetchMyTopics,
    refetchMyTopics,
    refetchCurrentTopic,
    setRefetchCurrentTopic: providerSetRefetchCurrentTopic,
    callsSelectedTab,
    setCallsSelectedTabName: providerSetSelectedTabName,
  }), [
    refetchClaimedCalls,
    refetchCompletedCalls,
    refetchUnclaimedCalls,
    refetchCall,
    refetchMyTopics,
    refetchCurrentTopic,
    callsSelectedTab, 
  ]);

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider