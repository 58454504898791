import React, { useState } from 'react';

// * MUI
import { Box, Typography } from '@mui/material';

// * Hooks & Utils
import { ApolloError } from '@apollo/client';

// * Icons
import { ReactComponent as DrawerIcon } from '../../../assets/icons/draverIcon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/searchIcon.svg';

// * Components
import SearchBar from './SearchBar';
import {
  DrawerButton,
  DrawerHeader as DrawerHeaderStyledComponent,
} from './styledComponents';

interface DrawerHeaderProps {
  isNotFound: ApolloError | undefined
  toggleDrawer: () => void
  // eslint-disable-next-line no-unused-vars
  onSearch: (search: string) => void
  onBack: () => void
  isSearching: boolean
}

const DrawerHeader = ({
  isNotFound,
  toggleDrawer,
  onSearch,
  onBack,
  isSearching,
}: DrawerHeaderProps) => {

  const [searchOpen, setSearchOpen] = useState(false);

  const handleToggleSearch = () => {
    setSearchOpen(open => !open);
  };

  const handleToggleBack = () => {
    handleToggleSearch();
    onBack();
  };

  return (
    <DrawerHeaderStyledComponent sx={{ maxHeight: '32px' }}>
      {searchOpen ? (
        <SearchBar
          onBack={handleToggleBack}
          onSearch={onSearch}
          disabled={isSearching}
        />
      ) : (
        <Box display="flex" alignItems="center">
          <Typography
            variant="h4"
            sx={{ fontSize: '24px', lineHeight: '32px', color: 'gray09' }}
          >
            Calls
          </Typography>
        </Box>
      )}

      {!isNotFound && !searchOpen && (
        <Box>
          <DrawerButton onClick={handleToggleSearch} sx={{ mr: 2 }}>
            <SearchIcon />
          </DrawerButton>

          <DrawerButton onClick={toggleDrawer}>
            <DrawerIcon />
          </DrawerButton>
        </Box>
      )}
    </DrawerHeaderStyledComponent>
  );
};

export default DrawerHeader;
