import React, { memo, useEffect } from 'react';
import { Box, Divider,  Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { DialContainer, KeypadNumber } from './DialPad.styled';
import { LargeRadiusButton } from '../Button/Button'


type DialPadProps = {
  meetingId?: string;
  meetingPassword?: string;
  dialNumber?: string;
  openDialer?: boolean
  handleDialInput: (search: string) => void
  handleKeypadPress: (event: KeyboardEvent) => void
  toggleManualDialer: () => void
};

const DialPad: React.FC<DialPadProps> = memo(({ meetingId, meetingPassword, dialNumber,openDialer,handleDialInput, handleKeypadPress, toggleManualDialer }) => {
  const buttons = [
    ...['1', '2', '3', '4', '5', '6', '7', '8', '9'],
    '0',
    '*',
    '#',
    '+',
  ];

  useEffect(() => {
    window.addEventListener('keydown', handleKeypadPress);
    return () => {
      window.removeEventListener('keydown', handleKeypadPress);
    };
  }, []);

  return (
    <DialContainer variant="persistent" anchor="right" open={openDialer}>
      <Box display="flex" justifyContent="space-between">
        <Typography fontSize={"1rem"} fontWeight={600} color="colors.gray08" gutterBottom>
          Dial In
        </Typography>
        <ArrowCircleRightOutlinedIcon onClick={toggleManualDialer} sx={{cursor: 'pointer'}}/>
      </Box>

      <Typography fontSize={"1rem"} p={"17px 0"} fontWeight={600} color="primary" gutterBottom >
        Meeting ID: <b>{meetingId}</b> | Password: <b>{meetingPassword}</b>
      </Typography>

      <Typography
        variant="h3"
        fontWeight="700"
        fontSize={'1.8rem'}
        sx={{ my: 1, color:'secondary.main', opacity: dialNumber ? "100%" : "10%", lineHeight: '40px' }}
      >
        {dialNumber || 'Enter Number'}
      </Typography>

      <Divider sx={{ borderBottom: '1px solid', borderColor: 'colors.gray03', mb: 2 }} />

      <Grid container padding="0 24px" rowGap={2} columnSpacing={2} justifyContent='center'>
        {buttons.map((value, index) => (
          <Grid size={{ xs: value === '0' ? 12 : 4 }} key={index} sx={{ display: value === '0' ? 'flex' : 'block', justifyContent: 'center' }}>
            <KeypadNumber
              variant="outlined"
              onClick={() => handleDialInput(value)}
            >
              {value}
            </KeypadNumber>
          </Grid>
        ))}
      </Grid>
      
      <Box sx={{ mt: 3 }}>
        <LargeRadiusButton customVariant="contained-green" onClick={() => alert('DIAL IN')}>
          Start call
        </LargeRadiusButton>
      </Box>
    </DialContainer>
  );
});

export default DialPad;
