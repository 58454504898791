import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import palette from '../../theme/palette';

export const StyledFormLabel = styled(FormLabel)({
  color: palette.colors.gray11,
  fontWeight: 400,
  '&.Mui-focused': {
    color: palette.colors.gray11,
  },
});

export const StyledFormControlLabel = styled(FormControlLabel)({
  color: palette.colors.gray11,
  fontWeight: 400,
  '&.MuiFormControlLabel-root': {
    padding: 0,
  },
  '& .MuiFormControlLabel-label': {
    fontWeight: 400,
    color: palette.colors.gray11,
  },
});