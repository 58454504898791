import { useState } from 'react';

// * Hooks & Utils
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';

// * Queries
import { ALL_NETWORKS_QUERY } from '../../graphql/calls/network';

// * Interfaces
import type { StringOptionType } from '../../types/Props';


export type FilterField = {
  uuid: string;
  label: string;
}

export type BooleanOptionType = boolean | undefined;

export type FilterProps = {
  allNetworks: StringOptionType[];
  networks: FilterField[];
  setNetworks: React.Dispatch<React.SetStateAction<FilterField[]>>;
  dateRangeStart?: Date;
  dateRangeEnd?: Date;
  setDateRange: React.Dispatch<React.SetStateAction<{ dateRangeStart?: Date; dateRangeEnd?: Date }>>;
}

const dateRangeInitial = {
  dateRangeStart: dayjs().utc().subtract(30, 'day').startOf('day')
    .toDate(),
  dateRangeEnd: dayjs().utc().endOf('day').toDate(),
};

const useFilterLogic = (): FilterProps => {
  const [networks, setNetworks] = useState<FilterField[]>([]);
  const [dateRange, setDateRange] = useState<{ dateRangeStart?: Date; dateRangeEnd?: Date }>(dateRangeInitial);

  const { data: { allNetworks = [] } = {} } = useQuery<{ allNetworks: StringOptionType[] }>(ALL_NETWORKS_QUERY);

  return {
    allNetworks,
    networks,
    setNetworks,
    dateRangeStart: dateRange.dateRangeStart,
    dateRangeEnd: dateRange.dateRangeEnd,
    setDateRange,
  };
};

export default useFilterLogic;
