import React, { memo, useContext, useCallback } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from '../../routes/paths';
import { formatDateTimeToUserTimezone } from '../../utils/dateHelpers';
import { AppContext } from '../../providers/AppProvider';
import { useUser } from '../../providers/UserProvider';
import {
  CallItem, CallTitle, HighlightCount, HighlightDot, HighlightStatus, NetworkLabel, NetworkValue,
} from './styledComponents';

// * Icons
import { ReactComponent as NoteRemoveIcon } from '../../assets/icons/noteRemoveIcon.svg';
import { ReactComponent as FailedIcon } from '../../assets/icons/failedIcon.svg';
import { ReactComponent as InProgressIcon } from '../../assets/icons/inProgressIcon.svg';
import { ReactComponent as UploadedOnRPIcon } from '../../assets/icons/uploadedOnRPIcon.svg';

// * Mutations
import { ASSIGN_USER_TO_REVIEW_MUTATION } from '../../graphql/user/user';

// * Interfaces
import type { CallTypes, CallType } from '../../types/Call';

type CallItemCardProps = {
  uuid: string;
  accountName: string;
  accountAvatarLogo?: string;
  review: CallType['review'];
  title: string;
  datetime: string;
  network: string;
  transcriptionStatus: string;
  selected: boolean;
  pendingHighlights?: number;
  resolvedHighlights?: number;
  expertName?: string;
  analystName?: string;
  reviewStatus: string;
  isResearchAnalyst?: boolean;
  isInSample: boolean;
  type: CallTypes;
  createdByResearchAnalyst: boolean;
  onClick: React.Dispatch<React.SetStateAction<string | undefined>>;
  openDrawerMobile?: boolean;
  setOpenDrawerMobile?: React.Dispatch<React.SetStateAction<boolean>>;
};

const CallItemCard: React.FC<CallItemCardProps> = memo(({
  uuid,
  accountName,
  accountAvatarLogo,
  review,
  title,
  datetime,
  network,
  transcriptionStatus,
  selected,
  pendingHighlights,
  resolvedHighlights,
  expertName = 'Not Available',
  analystName = 'Not Available',
  reviewStatus,
  isResearchAnalyst = false,
  isInSample,
  onClick,
  openDrawerMobile = false,
  type,
  createdByResearchAnalyst,
  setOpenDrawerMobile = () => {},
}) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { refetchCall } = useContext(AppContext);

  const handleSelectCall = useCallback(() => {
    onClick(uuid);
    setOpenDrawerMobile(!openDrawerMobile);
    navigate(paths.details.reverse({ callId: uuid }));
  }, [uuid, onClick, openDrawerMobile, setOpenDrawerMobile, navigate]);

  const [assignUser] = useMutation(ASSIGN_USER_TO_REVIEW_MUTATION, {
    onCompleted: ({ assignUserToReview }) => {
      toast.success(assignUserToReview.message);
      refetchCall?.();
    },
  });

  return (
    <CallItem onClick={handleSelectCall} className={selected ? 'selected-call-item' : ''}>
      <Divider flexItem sx={{ margin: '8px 0', borderColor: 'colors.gray03' }} />
      <Box display="flex" justifyContent="space-between" mb="2px">
        <Typography variant="body1" color="colors.gray06" sx={{ fontSize: '12px', lineHeight: '18px' }}>
          {formatDateTimeToUserTimezone(datetime, user.timezone)}
        </Typography>
        <Typography variant="body1" color="colors.gray06" sx={{ fontSize: '12px', lineHeight: '18px' }}>
          Compliance Reviewer
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        {type === 'call' && (
          <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '22px' }}>
            {network}
          </Typography>
        )}
      </Box>
      <Divider flexItem sx={{ margin: '8px 0', borderColor: 'colors.gray03' }} />
      
      <CallTitle variant="body2" color="colors.gray10">{title}</CallTitle>

      {type === 'call' && <Divider flexItem sx={{ mb: '10px', borderColor: 'colors.gray03' }} />}
      
      <Box display="flex" alignItems="center">
        {type === 'call' && (
          <>
            <Box display="flex" flexDirection="column" width="50%" mr="2px">
              <NetworkLabel variant="body1" color="colors.gray08">Expert Name</NetworkLabel>
              <NetworkValue variant="body1" color="colors.gray08">{expertName}</NetworkValue>
            </Box>
            <Box display="flex" flexDirection="column" width="50%" ml="2px">
              <NetworkLabel variant="body1" color="colors.gray08">Analyst</NetworkLabel>
              <NetworkValue variant="body1" color="colors.gray08">{analystName}</NetworkValue>
            </Box>
          </>
        )}
      </Box>      
      
      {!isResearchAnalyst && (
        <Box display="flex" alignItems="center" justifyContent="space-between" mt="8px">
          {(pendingHighlights && transcriptionStatus === 'Completed' && reviewStatus !== 'Reviewed' && isInSample) && (
            <Box display="flex" alignItems="center">
              <HighlightDot sx={{ backgroundColor: 'colors.warning04' }} />
              <HighlightStatus variant="body1" color="colors.gray08">Pending:</HighlightStatus>
              <HighlightCount variant="body1" color="colors.gray08">{pendingHighlights}</HighlightCount>
            </Box>
          )}
          {(resolvedHighlights && transcriptionStatus === 'Completed' && isInSample) && (
            <Box display="flex" alignItems="center">
              <HighlightDot sx={{ backgroundColor: 'colors.success03' }} />
              <HighlightStatus variant="body1" color="colors.gray08">Resolved:</HighlightStatus>
              <HighlightCount variant="body1" color="colors.gray08">{resolvedHighlights}</HighlightCount>
            </Box>
          )}
          {(transcriptionStatus === 'Completed' && !isInSample) && (
            <Box display="flex" alignItems="center">
              <NoteRemoveIcon />
              <HighlightStatus variant="body1" color="colors.gray08">Not included in sample.</HighlightStatus>
            </Box>
          )}
        </Box>
      )}
      {transcriptionStatus === 'Failed' && (
        <Box display="flex" alignItems="center" mt="8px">
          <FailedIcon />
          <HighlightStatus variant="body1" color="colors.gray08">Transcription Failed</HighlightStatus>
        </Box>
      )}
      {(transcriptionStatus.includes('In Process')) && (
        <Box display="flex" alignItems="center" mt="8px">
          <InProgressIcon />
          <HighlightStatus variant="body1" color="colors.gray08">Processing…</HighlightStatus>
        </Box>
      )}
      {isResearchAnalyst && createdByResearchAnalyst && (
        <Box display="flex" alignItems="center" mt="8px">
          <UploadedOnRPIcon />
          <HighlightStatus variant="body1" color="colors.gray08">Uploaded on research portal</HighlightStatus>
        </Box>
      )}
    </CallItem>
  );
});

export default CallItemCard;
