/* eslint-disable import/order */
// * MUI
import { PopoverOrigin } from '@mui/material/Popover';

// * Hooks & Utils
import palette from '../../../theme/palette';

// * Interfaces
import type { MenuProps as MUIMenuProps } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import utc from 'dayjs/plugin/utc';

export const MenuProps: Partial<MUIMenuProps> = {
  autoFocus: false,
  anchorOrigin: { vertical: 'top', horizontal: 'right' } as PopoverOrigin,
  transformOrigin: { vertical: 'top', horizontal: 'left' } as PopoverOrigin,
  PaperProps: {
    style: {
      maxHeight: '275px',
      width: '310px',
      border: '1px solid',
      borderColor: palette.primary.main,
      borderRadius: '8px',
      boxShadow: '0px 8px 9px rgba(226, 226, 226, 0.35)',
    },
  },
};

dayjs.extend(utc);

const today = dayjs().utc();

export const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'Today',
    getValue: () => [today.startOf('day'), today.endOf('day')],
  },
  {
    label: 'Yesterday',
    getValue: () => {
      const prevDay = today.subtract(1, 'day');
      return [prevDay.startOf('day'), prevDay.endOf('day')];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => [today.subtract(7, 'day').startOf('day'), today.endOf('day')],
  },
  {
    label: 'Last 14 Days',
    getValue: () => [today.subtract(14, 'day').startOf('day'), today.endOf('day')],
  },
  {
    label: 'Last 30 Days',
    getValue: () => [today.subtract(30, 'day').startOf('day'), today.endOf('day')],
  },
  { label: 'All time', getValue: () => [today.subtract(10, 'years').startOf('year'), today.add(1, 'year').endOf('year')] },
];

export default MenuProps;
  

