import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import paths from "../routes/paths";
import theme from '../theme/index';
import client from "../services/ApiService";

// Pages
import Dashboard from '../pages/Dashboard';
import Page404 from '../pages/404';
import CallDetails from '../pages/CallDetails';
import TestComponents from '../pages/TestComponents';

import { ThemeProvider } from '@mui/material';
import { AppProvider } from '../providers';
import MainLayout from '../layouts/Main';

export const AppRoutes: React.FC = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <DndProvider backend={HTML5Backend}>
            <Routes>
              
              <Route element={<MainLayout />}>
                <Route path="/" element={<Navigate to={paths.home.reversed()} />} />
                <Route path={paths.details.pattern} element={<CallDetails />} />
              </Route>

              <Route path={paths.testComponents.pattern} element={<TestComponents />} />
              <Route path={paths.signIn.pattern} element={<Dashboard />} />
              <Route path="*" element={<Page404 />} />
              
            </Routes>
          </DndProvider>
        </AppProvider>
      </ThemeProvider>
    </ApolloProvider>
  </LocalizationProvider>
);

export default AppRoutes;
