import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { MetricsDebugOverlay, MetricsProvider, usePerformanceMark, Stage } from '@cabify/prom-react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { LicenseInfo } from '@mui/x-license';

import './assets/scss/index.scss';
import theme from './theme/index';
import AppRoutes from './routes/AppRoutes';

const normalizePath = (path: string) => {
  /* Change this to normalize router paths when sending to PROMETHEUS_PUSH_GATEWAY */
  return path;
};

console.log(`Environment: ${process.env.NODE_ENV}`);
console.log(`Prometheus Push Gateway URL: ${process.env.REACT_APP_PROMETHEUS_PUSH_GATEWAY_URL}`);

const metricsAggregatorUrl = `${process.env.REACT_APP_PROMETHEUS_PUSH_GATEWAY_URL}/metrics`;

const licenseKey = process.env.REACT_APP_MUI_X_PRO_LICENSE_KEY;
if (!licenseKey) {
  throw new Error('License key is not defined');
}
LicenseInfo.setLicenseKey(licenseKey);

const App: React.FC = () => {
  const [isMetricsDebugOverlayEnabled, setIsMetricsDebugOverlayEnabled] = useState(
    process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development'
  );

  const handleCloseOverlay = () => {
    setIsMetricsDebugOverlayEnabled(false);
  };

  /* TODO: This is a gloal usePerformanceMark, 
    we should remove it from here and place in each page for better metrics organization
  */
  usePerformanceMark(Stage.Usable, 'global-load');
  usePerformanceMark(Stage.Complete, 'global-load');

  return (
    <MetricsProvider
      appName="aca-scheduler-frontend"
      owner="encore"
      getNormalizedPath={normalizePath}
      metricsAggregatorUrl={metricsAggregatorUrl}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <AppRoutes />
        </Router>
      </ThemeProvider>

      {isMetricsDebugOverlayEnabled && (
        <MetricsDebugOverlay
          withLogger
          onClose={handleCloseOverlay}
        />
      )}
    </MetricsProvider>
  );
};

export default App;