import React from 'react';
import { Paper, Typography } from '@mui/material';

interface SummaryCardProps {
  title: string;
  content: string;
}

const SummaryCard: React.FC<SummaryCardProps> = ({ title, content }) => (
  <Paper elevation={0}  sx={{ padding: 4, border: "solid 1px", borderColor: 'colors.gray03', borderRadius: "8px" }}>
    <Typography variant="body2" fontWeight="bold" gutterBottom>
      {title}
    </Typography>
    <Typography variant="subtitle1" color="colors.gray07" fontWeight={400}>
      {content}
    </Typography>
  </Paper>
);

export default SummaryCard;
