import React from 'react';
import HeaderBar from '../components/HeaderBar';

const Home: React.FC = () => (
  <div>
    <h1>Welcome to the dashboard</h1>
  </div>
);

export default Home;
