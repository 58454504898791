import React from 'react';
import { PaddingButton, LargeButton, LargeRadiusButton } from '../components/Button/Button';

// Define a union type for all possible variants
type ButtonVariant = 'contained-blue' | 'contained-green' | 'contained-red' | 'contained-yellow' | 'outlined-blue' | 'outlined-gray' | 'outlined-red' | 'outlined-yellow';

// Declare the customVariants array with correct type
const customVariants: ButtonVariant[] = [
  'contained-blue',
  'contained-green',
  'contained-red',
  'contained-yellow',
  'outlined-blue',
  'outlined-gray',
  'outlined-red',
  'outlined-yellow',
];

const TestComponents: React.FC = () => {
  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>

      <h1 style={{ textAlign: 'center', marginBottom: '40px' }}>Button Variants Test</h1>

      {customVariants.map((variant) => (
        <div key={variant} style={{ marginBottom: '40px' }}>

          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
            {variant.replace('-', ' ').toUpperCase()}
          </h2>

          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
            <div>
              <h4 style={{ textAlign: 'center', marginBottom: '10px' }}>PaddingButton</h4>
              <PaddingButton customVariant={variant} onClick={() => alert(`${variant} PaddingButton Clicked`)}>
                {variant.replace('-', ' ')} Padding
              </PaddingButton>
            </div>
            <div>
              <h4 style={{ textAlign: 'center', marginBottom: '10px' }}>LargeButton</h4>
              <LargeButton customVariant={variant} onClick={() => alert(`${variant} LargeButton Clicked`)}>
                {variant.replace('-', ' ')} Large
              </LargeButton>
            </div>
            <div>
              <h4 style={{ textAlign: 'center', marginBottom: '10px' }}>LargeRadiusButton</h4>
              <LargeRadiusButton customVariant={variant} onClick={() => alert(`${variant} LargeRadiusButton Clicked`)}>
                {variant.replace('-', ' ')} Large Radius
              </LargeRadiusButton>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TestComponents;
