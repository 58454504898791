/* eslint-disable import/order, no-unused-vars */
import React, { useEffect, useRef, useState, useCallback } from 'react';

// * MUI
import {
  TextField, InputAdornment, IconButton, styled,
} from '@mui/material';

// * Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// * Utils
import { debounce } from 'lodash';
import palette from '../../../theme/palette';

// * Interfaces
import type { DebouncedFunc } from 'lodash';

const SearchField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    backgroundColor: palette.colors.gray01,
    '& fieldset': {
      borderColor: palette.colors.gray02,
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: palette.colors.gray02,
    },
  },
});

interface SearchBarProps {
  onBack: () => void;
  onSearch: (search: string) => void;
  disabled: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({ onBack, onSearch, disabled }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  
  const debouncedSearch = useRef<DebouncedFunc<(query: string) => void>>(
    debounce((query: string) => onSearch(query), 700)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleSearch = useCallback(
    (query: string) => {
      debouncedSearch(query);
    },
    [debouncedSearch]
  );

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchValue(value);
      handleSearch(value);
    },
    [handleSearch]
  );

  const handleOnBack = useCallback(() => {
    onBack();
  }, [onBack]);

  const startAdornment = (
    <InputAdornment position="start">
      <IconButton onClick={!disabled ? handleOnBack : undefined} disabled={disabled}>
        <ArrowBackIcon />
      </IconButton>
    </InputAdornment>
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        debouncedSearch.cancel();
        onSearch(searchValue);
        event.preventDefault();
      }
      if (event.key === 'Escape') {
        handleOnBack();
      }
    },
    [debouncedSearch, onSearch, searchValue, handleOnBack]
  );

  return (
    <SearchField
      fullWidth
      autoComplete="off"
      autoFocus
      variant="outlined"
      placeholder="Search by Call, Expert, Project ID..."
      value={searchValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      slotProps={{
        input: {
          startAdornment: startAdornment,
        },
      }}
    />
  );
};


export default SearchBar;
