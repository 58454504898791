import { compile, PathFunction } from 'path-to-regexp';

class RoutePath {
  public pattern: string;
  public reversed: PathFunction<object>;

  constructor(pattern: string) {
    if (!pattern) throw new Error('`pattern` is required');
    this.pattern = pattern;
    this.reversed = compile(this.pattern);
  }

  reverse(params: Record<string, any>): string {
    return this.reversed(params);
  }
}

export default RoutePath;
