import React from 'react';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';

import Radio from './Radio';
import { StyledFormControlLabel, StyledFormLabel } from './RadioGroupYesNo.styled';

/**
 * @interface RadioGroupYesNoProps
 * @property {string} formLabel - The label to be displayed next to the radio buttons.
 * @property {boolean | undefined} value - The current selected value, 'true', 'false', or 'undefined'.
 * @property {(value: boolean | undefined) => void} onChange - The callback function to update the parent state with the selected value.
 */
interface RadioGroupYesNoProps {
  formLabel: string;
  value: boolean | undefined | null;
  onChange: (value: boolean | undefined) => void;
}

const RadioGroupYesNo: React.FC<RadioGroupYesNoProps> = ({ formLabel, value, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'Yes') {
      onChange(true);
    } else if (event.target.value === 'No') {
      onChange(false);
    } else {
      onChange(undefined);
    }
  };

  return (
    <FormControl>
      <Stack direction="row" alignItems="center" spacing={2}>
        <StyledFormLabel>{formLabel}</StyledFormLabel>
        <RadioGroup
          row
          aria-labelledby="radio-group-label"
          name="radio-group"
          value={value === undefined ? '' : value ? 'Yes' : 'No'}
          onChange={handleChange}
        >
          <Stack direction="row" spacing={1}>
            <StyledFormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <StyledFormControlLabel value="No" control={<Radio />} label="No" />
          </Stack>
        </RadioGroup>
      </Stack>
    </FormControl>
  );
};

export default RadioGroupYesNo;
