/* eslint-disable import/order, no-unused-vars */
import React, { useEffect, useState } from 'react';

// * MUI
import {
  Box, FormControl, MenuItem, Select,
} from '@mui/material';

// * Components
import {
  AvatarContainer, Avatar, EmptyTypography, SelectedTypography,
  CustomCheckbox, AnalystInput, NameContainer,
} from './styledComponents';
import palette from '../../theme/palette';

// * Hooks & Utils
import paths from '../../routes/paths';

// * Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// * Interfaces
import type { SelectChangeEvent } from '@mui/material/Select';
import type { AnalystType } from '../../types/Call';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '275px',
      width: '310px',
      border: '1px solid',
      borderColor: palette.primary.main,
      borderRadius: '8px',
      boxShadow: '0px 8px 9px rgba(226, 226, 226, 0.35)',
    },
  },
};

interface AnalystDropdownProps {
  accountAnalysts: AnalystType[]
  callAnalysts: AnalystType[]
  handleUpdateCallAnalysts: (value: AnalystType[]) => void;
}

const AnalystDropdown = ({
  accountAnalysts,
  callAnalysts,
  handleUpdateCallAnalysts,
}: AnalystDropdownProps) => {
  const [selectedData, setSelectedData] = useState(accountAnalysts);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const { target: { value } } = event;
    const newSelectedValue = typeof value === 'string' ? value.split(',') : value;
    const selectedObjects = newSelectedValue
      .map(v => accountAnalysts.find(item => item.uuid === v))
      .filter(item => item !== undefined);
    handleUpdateCallAnalysts(selectedObjects as AnalystType[]);
  };

  const getDataLabel = (uuid: string): string => accountAnalysts.find(d => d.uuid === uuid)?.name || '';

  const renderValue = (selected: string[]) => {
    if (selected.length === 0) {
      return (
        <Box display="flex" alignItems="center">
          <EmptyTypography variant="subtitle1" color="colors.gray08">
            Not Available
          </EmptyTypography>
        </Box>
      );
    }

    return (
      <Box display="flex" alignItems="center">
        <SelectedTypography variant="subtitle1" color="colors.gray08">
          {selected.map(analyst => getDataLabel(analyst)).join(', ') || 'Not Available'}
        </SelectedTypography>
      </Box>
    );
  };

  useEffect(() => {
    setSelectedData(accountAnalysts);
  }, [accountAnalysts]);

  return (
    <FormControl sx={{ maxWidth: '100%' }}>
      <Select
        value={callAnalysts.map(a => a.uuid)}
        onChange={handleChange}
        input={<AnalystInput />}
        multiple
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={ExpandMoreIcon}
        MenuProps={MenuProps}
        renderValue={renderValue}
      >
        {selectedData
          ? [...selectedData]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(d => (
              <MenuItem sx={{ padding: '6px 12px' }} key={d.uuid} value={d.uuid}>
                <CustomCheckbox checked={callAnalysts.some(a => a.uuid === d.uuid)} />
                <AvatarContainer>
                  <Avatar alt={d.name} src={paths.home.reversed()} />
                </AvatarContainer>
                <NameContainer variant="body1" color="colors.gray08">
                  {d.name}
                </NameContainer>
              </MenuItem>
            )) : []}
      </Select>
    </FormControl>
  );
};

export default AnalystDropdown;
