import React, { FC } from 'react';
import { Box, Typography, SxProps } from '@mui/material';

type CallDetailsItemProps = {
  title: string;
  isTypography?: boolean;
  sx?: SxProps;
  children: React.ReactNode;
};

const CallDetailsItem: FC<CallDetailsItemProps> = ({
  title,
  isTypography = false,
  sx = null,
  children,
}) => (
  <Box sx={sx}>
    <Typography
      variant="caption"
      color="colors.gray06"
      sx={{ marginBottom: '4px' }}
    >
      {title}
    </Typography>
    <Box>
      {isTypography ? (
        <Box display="flex" alignItems="center" minHeight={36}>
          <Typography
            variant="subtitle2"
            color="colors.gray08"
            sx={{ fontSize: '16px', textTransform: 'none' }}
          >
            {children}
          </Typography>
        </Box>
      ) : (
        children
      )}
    </Box>
  </Box>
);

export default CallDetailsItem;
