import React, { memo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { CallType } from '../../types/Call';
import palette from '../../theme/palette';
import UnclaimedCallItemCard from '../Card/UnclaimedCallItemCard';
import { formatDate } from '../../utils/dateHelpers';

const GroupedBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const Ellipse = styled(Box)({
  display: 'flex',
  width: 13,
  height: 13,
  backgroundColor: palette.colors.gray04,
  border: '1px solid',
  borderColor: palette.colors.gray05,
  borderRadius: '50%',
  marginRight: '8px',
});

type UnclaimedCallListProps = {
  calls: CallType[];
  onSelect: (uuid: string) => void;
  selectedCallId?: string;
  isResearchAnalyst?: boolean;
  openDrawerMobile?: boolean;
  setOpenDrawerMobile?: React.Dispatch<React.SetStateAction<boolean>>;
};

const UnclaimedCallList: React.FC<UnclaimedCallListProps> = memo(({
  calls,
  onSelect,
  selectedCallId = undefined,
  openDrawerMobile = false,
  setOpenDrawerMobile = () => {},
}) => {
  const groupedCalls = calls.reduce<{ [key: string]: CallType[] }>(
    (acc, transcript) => {
      const date = formatDate(transcript.datetime);
      if (!acc[date]) acc[date] = [];
      acc[date].push(transcript);
      return acc;
    },
    {}
  );

  return (
    <>
      {Object.keys(groupedCalls).map(date => (
        <GroupedBox key={date} className="grouped-call-box">
          <Box display="flex" alignItems="center" mb="6px">
            <Ellipse />
            <Typography variant="subtitle2" color="colors.gray08">
              {date}
            </Typography>
          </Box>
          <Box pl="20px">
            {groupedCalls[date].map(
              ({
                uuid,        
                account: { name: accountName, settings: { avatarLogo: accountAvatarLogo } },
                status: { value: status },
                inSample: isInSample,
                name: title,
                datetime,
                network: { value: network },
                expertName,
                analystName,
                type
                
              }) => (
                <UnclaimedCallItemCard
                  key={uuid}
                  uuid={uuid}
                  accountName={accountName}
                  accountAvatarLogo={accountAvatarLogo}
                  status={status}
                  isInSample={isInSample}
                  title={title}
                  datetime={datetime}
                  network={network}
                  onClick={() => onSelect(uuid)}
                  selected={uuid === selectedCallId}
                  expertName={expertName}
                  analystName={analystName}
                  type={type}
                  openDrawerMobile={openDrawerMobile}
                  setOpenDrawerMobile={setOpenDrawerMobile}
                />
              )
            )}
          </Box>
        </GroupedBox>
      ))}
    </>
  );
});

export default UnclaimedCallList;
