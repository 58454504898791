import { styled } from '@mui/material/styles';
import { Radio as MuiRadio } from '@mui/material';

import palette from '../../theme/palette';

const StyledRadio = styled(MuiRadio)({
  color: palette.colors.gray04,
  '&.Mui-checked': {
    color: palette.primary.main,
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.MuiRadio-root': {
    padding: 0,
  },
});

export default StyledRadio;