/* eslint-disable no-unused-vars */
import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
  } from 'react';

import { Outlet } from 'react-router-dom';

// * Hooks & Utils
import { ApolloError, useLazyQuery } from '@apollo/client';
import { isEmpty } from 'lodash';

// * Queries
import { USER_QUERY } from '../graphql/auth/account';

// * Interfaces
import type { User } from '../types/User';

type UserContextType = {
  user: User;
  setUser: (user: User) => void;
  refetchUser: () => Promise<void>;
  error: ApolloError | undefined;
  loading: boolean;
  called: boolean;
};

const defaultUser: User = {
  email: '',
  firstName: 'Jessica',
  lastName: 'Born',
  uuid: '',
  id: '',
  timezone: '',
  avatar: "",
  isSuperuser: false,
  allowSwitchRolesOnFrontend: false,
  accountFullLogo: '',
  accountAvatarLogo: '',
};

export const UserContext = createContext<UserContextType>({
  user: defaultUser,
  setUser: () => {},
  refetchUser: async () => {},
  error: undefined,
  loading: false,
  called: false,
});

type UserContextProviderProps = {
  children: ReactNode;
};

const UserProvider: React.FC<UserContextProviderProps> = ({ children }) => {
  const [getUser, { data, refetch, error, loading, called }] = useLazyQuery(USER_QUERY);

  const [user, setUser] = useState<User>(data?.user || defaultUser);

  useEffect(() => {
    if (isEmpty(data?.user)) {
      getUser();
    }
  }, [data, getUser]);

  const handleRefetchUser = async () => {
    const { data: newData } = await refetch();
    if (newData?.user) setUser(newData.user);
  };

  const value = useMemo(
    () => ({
      user,
      setUser,
      refetchUser: handleRefetchUser,
      error,
      loading,
      called,
    }),
    [user, handleRefetchUser, error, loading, called]
  );

  return <UserContext.Provider value={value}><Outlet/></UserContext.Provider>;
};

export default UserProvider;
export const useUser = () => useContext(UserContext);
