import { gql } from '@apollo/client';


export const ASSIGN_USER_TO_REVIEW_MUTATION = gql`
  mutation assignUserToReview($instanceUuid: UUID!, $userUuid: UUID) {
    assignUserToReview(instanceUuid: $instanceUuid, userUuid: $userUuid) {
      status
      message
      reviewer {
        id
        uuid
        firstName
        lastName
      }
    }
  }
`;
