import { CallType } from "./Call";

export const unclaimedCallObject: CallType = {
    uuid: "123e4567-e89b-12d3-a456-426614174000",
    account: {
        uuid: "321e4567-e89b-12d3-a456-426614174001",
        name: "Test Account",
        settings: {
            fullLogo: "https://example.com/full-logo.png",
            avatarLogo: ""
        }
    },
    type: 'call',
    name: "Test Call",
    datetime: "2024-11-18T15:00:00Z",
    topic: "Quarterly Earnings Call",
    sentiment: "None",
    summary: `OpenAI is a leading artificial intelligence research and deployment company committed to ensuring that artificial general intelligence (AGI) benefits all of humanity...
    OpenAI's mission is to ensure that AGI is developed in a manner that is safe and aligned with human values.`,
    analystName: "John Doe",
    inSample: true,
    inResearch: false,
    priority: 10,
    expertName: "Jane Smith",
    expertPerspective: "Insights on industry trends.",
    expertSummary: `With over a decade of leadership experience across sales, marketing, and business strategy...
    My career spans key executive roles in major global companies, where I have consistently delivered results through strategic vision, market expansion, and customer-centric approaches.`,
    expertEmploymentHistory: [
        "Independent Business Advisor | Freelance | 1/2023 to Present (Private)",
        "Vice President of Sales Europe South | Syniverse | 4/2019 to 11/2022 (Private)",
        "Chief Marketing Officer - Telefonica Global Solutions | Telefónica | 2/2017 to 2/2019 (Public)",
    ],
    expertConsultingSummary: [
        "Advised on portfolio diversification strategies for high-net-worth clients, focusing on emerging markets.",
        "Provided detailed risk assessment and mitigation plans for technology sector investments.",
        "Conducted financial analysis and created investment models for real estate opportunities.",
    ],
    network: {
        key: "glg",
        value: "GLG"
    },
    status: {
        key: "unclaimed",
        value: "Unclaimed"
    },
    review: {
        uuid: "456e4567-e89b-12d3-a456-426614174002",
        reviewer: null,
        status: {
            key: "unclaimed",
            value: "Unclaimed"
        }
    },
    callAnalysts: [{
        uuid: "101e4567-e89b-12d3-a456-426614174004",
        name: "Jane Doe",
        email: "jane.doe@example.com",
        ignore: false
    },{
        uuid: "101e4567-e89b-12d3-a456-426614174005",
        name: "Chris Doe",
        email: "Chris.Doe@example.com",
        ignore: false
    }],
    transcript: {
        audioUrl: "https://example.com/audio.mp3",
        isFileAText: false,
        status: {
            key: "transcribed",
            value: "Transcribed"
        }
    },
    participants: [{
        uuid: "202e4567-e89b-12d3-a456-426614174005",
        updatedAt: "2024-11-08T16:00:00Z",
        name: "Michael Participant",
        originalName: "Mike P."
    }],
    meetingParticipants: [{
        name: "Sarah Brown",
        email: "sarah.brown@example.com"
    },{
        name: "Hassan Bechtelar", 
        email: "hassan_b@yahoo.com"
    },{
        name: "Augustine Smith",
        email: "augustine74@hotmail.com"
    }, {
        name: "Ray Johnson",
        email: "ray78@hotmail.com"
    }, {
        name: "Hazle Sporer",
        email: "hazle.sporer24@hotmail.com"
    }],
    createdByResearchAnalyst: true
};


export const claimedCallObject: CallType = {
    uuid: "123e4567-e89b-12d3-a456-426614174001",
    account: {
        uuid: "321e4567-e89b-12d3-a456-426614174001",
        name: "Test Account",
        settings: {
            fullLogo: "https://example.com/full-logo.png",
            avatarLogo: ""
        }
    },
    type: 'call',
    name: "Claimed Call",
    datetime: "2024-11-20T15:00:00Z",
    topic: "Quarterly Earnings Call",
    sentiment: "None",
    summary: `OpenAI is a leading artificial intelligence research and deployment company committed to ensuring that artificial general intelligence (AGI) benefits all of humanity...
    OpenAI's mission is to ensure that AGI is developed in a manner that is safe and aligned with human values.`,
    analystName: "Robert Doe",
    inSample: true,
    inResearch: false,
    priority: 16,
    expertName: "Chris Redfield",
    expertPerspective: "Insights on industry trends.",
    expertSummary: `With over a decade of leadership experience across sales, marketing, and business strategy...
    My career spans key executive roles in major global companies, where I have consistently delivered results through strategic vision, market expansion, and customer-centric approaches.`,
    expertEmploymentHistory: [
        "Independent Business Advisor | Freelance | 1/2023 to Present (Private)",
        "Vice President of Sales Europe South | Syniverse | 4/2019 to 11/2022 (Private)",
        "Chief Marketing Officer - Telefonica Global Solutions | Telefónica | 2/2017 to 2/2019 (Public)",
    ],
    expertConsultingSummary: [
        "Advised on portfolio diversification strategies for high-net-worth clients, focusing on emerging markets.",
        "Provided detailed risk assessment and mitigation plans for technology sector investments.",
        "Conducted financial analysis and created investment models for real estate opportunities.",
    ],
    network: {
        key: "glg",
        value: "GLG"
    },
    status: {
        key: "claimed",
        value: "Claimed"
    },
    review: {
        uuid: "456e4567-e89b-12d3-a456-426614174002",
        reviewer: {
            email: "reviewer@example.com",
            firstName: "Alice",
            allowSwitchRolesOnFrontend: true,
            lastName: "Smith",
            uuid: "789e4567-e89b-12d3-a456-426614174003",
            id: "reviewer-123",
            timezone: "UTC",
            avatar: "",
            isSuperuser: true,
            accountFullLogo: "",
            accountAvatarLogo: ""
        },
        status: {
            key: "unclaimed",
            value: "Unclaimed"
        }
    },
    callAnalysts: [{
        uuid: "101e4567-e89b-12d3-a456-426614174004",
        name: "Jane Doe",
        email: "jane.doe@example.com",
        ignore: false
    },{
        uuid: "101e4567-e89b-12d3-a456-426614174005",
        name: "Chris Doe",
        email: "Chris.Doe@example.com",
        ignore: false
    }],
    transcript: {
        audioUrl: "https://example.com/audio.mp3",
        isFileAText: false,
        status: {
            key: "transcribed",
            value: "Transcribed"
        }
    },
    participants: [{
        uuid: "202e4567-e89b-12d3-a456-426614174005",
        updatedAt: "2024-11-08T16:00:00Z",
        name: "Michael Participant",
        originalName: "Mike P."
    }],
    meetingParticipants: [{
        name: "Sarah Brown",
        email: "sarah.brown@example.com"
    }],
    createdByResearchAnalyst: true
};


export const completedCallObject: CallType = {
    uuid: "123e4567-e89b-12d3-a456-426614174002",
    account: {
        uuid: "321e4567-e89b-12d3-a456-426614174001",
        name: "Test Account",
        settings: {
            fullLogo: "https://example.com/full-logo.png",
            avatarLogo: ""
        }
    },
    type: 'call',
    name: "Completed Call",
    datetime: "2024-11-20T15:00:00Z",
    topic: "Quarterly Earnings Call",
    sentiment: "None",
    summary: "This is a summary of the call discussing quarterly results and projections.",
    analystName: "Robert Doe",
    inSample: true,
    inResearch: false,
    priority: 16,
    expertName: "Chris Redfield",
    expertPerspective: "Insights on industry trends.",
    expertSummary: "Provided strategic consulting for major tech firms.",
    expertEmploymentHistory: [
        "Independent Business Advisor | Freelance | 1/2023 to Present (Private)",
        "Vice President of Sales Europe South | Syniverse | 4/2019 to 11/2022 (Private)",
        "Chief Marketing Officer - Telefonica Global Solutions | Telefónica | 2/2017 to 2/2019 (Public)",
    ],
    expertConsultingSummary: [
        "Advised on portfolio diversification strategies for high-net-worth clients, focusing on emerging markets.",
        "Provided detailed risk assessment and mitigation plans for technology sector investments.",
        "Conducted financial analysis and created investment models for real estate opportunities.",
    ],
    network: {
        key: "glg",
        value: "GLG"
    },
    status: {
        key: "completed",
        value: "Completed"
    },
    review: {
        uuid: "456e4567-e89b-12d3-a456-426614174002",
        reviewer: {
            email: "reviewer@example.com",
            firstName: "Alice",
            allowSwitchRolesOnFrontend: true,
            lastName: "Smith",
            uuid: "789e4567-e89b-12d3-a456-426614174003",
            id: "reviewer-123",
            timezone: "UTC",
            avatar: "",
            isSuperuser: true,
            accountFullLogo: "",
            accountAvatarLogo: ""
        },
        status: {
            key: "completed",
            value: "Completed"
        }
    },
    callAnalysts: [{
        uuid: "101e4567-e89b-12d3-a456-426614174004",
        name: "Jane Doe",
        email: "jane.doe@example.com",
        ignore: false
    },{
        uuid: "101e4567-e89b-12d3-a456-426614174005",
        name: "Chris Doe",
        email: "Chris.Doe@example.com",
        ignore: false
    }],
    transcript: {
        audioUrl: "https://example.com/audio.mp3",
        isFileAText: false,
        status: {
            key: "transcribed",
            value: "Transcribed"
        }
    },
    participants: [{
        uuid: "202e4567-e89b-12d3-a456-426614174005",
        updatedAt: "2024-11-08T16:00:00Z",
        name: "Michael Participant",
        originalName: "Mike P."
    }],
    meetingParticipants: [{
        name: "Sarah Brown",
        email: "sarah.brown@example.com"
    }],
    createdByResearchAnalyst: true
};
