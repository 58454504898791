import { styled } from '@mui/material/styles';
import { Button as MuiButton, ButtonProps } from '@mui/material';

import palette from '../../theme/palette';

const sharedStyles = {
  minHeight: '48px',
  fontSize: '14px',
  lineHeight: '20px',
  whiteSpace: 'nowrap',
};

const variantStyles = {
  'contained-blue': {
    variant: 'contained',
    backgroundColor: palette.primary.main,
    color: palette.white,
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
    '&.Mui-disabled': {
      backgroundColor: palette.primary.light,
    },
  },
  'contained-green': {
    variant: 'contained',
    backgroundColor: palette.success.main,
    color: palette.white,
    '&:hover': {
      backgroundColor: palette.colors.success04,
    },
    '&.Mui-disabled': {
      backgroundColor: palette.colors.success02,
    },
  },
  'contained-red': {
    variant: 'contained',
    backgroundColor: palette.error.main,
    color: palette.white,
    '&:hover': {
      backgroundColor: palette.colors.error04,
    },
    '&.Mui-disabled': {
      backgroundColor: palette.colors.error02,
    },
  },
  'contained-yellow': {
    variant: 'contained',
    backgroundColor: palette.warning.main,
    color: palette.white,
    '&:hover': {
      backgroundColor: palette.colors.warning04,
    },
    '&.Mui-disabled': {
      backgroundColor: palette.colors.warning02,
    },
  },
  'outlined-blue': {
    variant: 'outlined',
    border: `1px solid ${palette.primary.main}`,
    color: palette.primary.main,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: palette.primary.light,
    },
  },
  'outlined-gray': {
    variant: 'outlined',
    border: `1px solid ${palette.colors.gray05}`,
    color: palette.colors.gray05,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: palette.colors.gray02,
    },
  },
  'outlined-red': {
    variant: 'outlined',
    border: `1px solid ${palette.error.main}`,
    color: palette.error.main,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: palette.colors.error02,
    },
  },
  'outlined-yellow': {
    variant: 'outlined',
    border: `1px solid ${palette.warning.main}`,
    color: palette.colors.gray09,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: palette.colors.warning02,
    },
  },
};

export interface CustomButtonProps extends ButtonProps {
  customVariant: keyof typeof variantStyles;
}

export const StyledPaddingButton = styled(MuiButton)<CustomButtonProps>(({ customVariant }) => ({
  ...sharedStyles,
  ...variantStyles[customVariant || 'contained-blue'],
  padding: '12px 16px',
  borderRadius: '15px',
}));

export const StyledLargeButton = styled(MuiButton)<CustomButtonProps>(({ customVariant }) => ({
  ...sharedStyles,
  ...variantStyles[customVariant || 'contained-blue'],
  width: '209px',
  borderRadius: '12px',
}));

export const StyledLargeRadiusButton = styled(MuiButton)<CustomButtonProps>(({ customVariant }) => ({
  ...sharedStyles,
  ...variantStyles[customVariant || 'contained-blue'],
  width: '230px',
  height: '61px',
  borderRadius: '62px',
}));