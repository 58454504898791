import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import palette from '../../theme/palette';

export const CallItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  border: '1px solid',
  borderColor: palette.colors.gray03,
  borderRadius: '12px',
  backgroundColor: palette.white,
  padding: '16px 20px',
  marginBottom: '12px',
  cursor: 'pointer',
  position: 'relative',
  transition: 'all 250ms',
  '&:hover': {
    borderColor: '#70BDFF',
    boxShadow: '0px 4px 13px rgba(188, 188, 188, 0.25)',
  },
  '&.selected-call-item': {
    borderColor: palette.colors.primary05,
    background: 'linear-gradient(0deg, rgba(80, 156, 241, 0.05) 0%, rgba(80, 156, 241, 0.05) 100%), #FFFFFF',
    boxShadow: '0px 4px 13px 0px rgba(188, 188, 188, 0.25)',
  },
  '&.pending-call-item': {
    background: palette.colors.warning01,
  },
  '&.disabled-call-item': {
    cursor: 'default',
    '&:hover': {
      borderColor: palette.colors.gray03,
      boxShadow: 'none',
    },
  },
});

export const CallTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 600,
  height: '48px',
  maxHeight: '48px',
  lineHeight: '24px',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
});

export const HighlightDot = styled(Box)({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
});

export const HighlightStatus = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  marginLeft: '8px',
});

export const HighlightCount = styled(Typography)({
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '22px',
  marginLeft: '4px',
});

export const NetworkLabel = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
});

export const NetworkValue = styled(Typography)({
  fontSize: '16px',
  fontWeight: 700,
  height: '20px',
  maxHeight: '20px',
  maxWidth: '174px',
  lineHeight: '20px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const UnclaimedCardStatus = styled(Typography)({
  fontSize: '12px',
  lineHeight: '18px',
  marginLeft: '12px',
});
