import React from 'react';

import StyledRadio from './Radio.styled';

/**
 * @interface RadioProps
 * @property {string} [value] - The value of the radio button.
 * @property {string} [name] - The name of the radio button group.
 * @property {(event: React.ChangeEvent<HTMLInputElement>) => void} [onChange] - The callback function to update the parent state with the selected value.
 */
interface RadioProps {
  value?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Radio: React.FC<RadioProps> = ({ value, name, onChange }) => (
  <StyledRadio
    onChange={onChange}
    value={value}
    name={name}
  />
);

export default Radio;