/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';

// * MUI
import { styled } from '@mui/material/styles';
import {
  Avatar as MUIAvatar, Box, Typography, FormControl, MenuItem, Checkbox,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// * Components
import { FilterInput, NameContainer } from './styledComponents';
import FiltersSearch from './FiltersSearch';

// * Hooks & Utils
import palette from '../../../theme/palette';
import paths from '../../../routes/paths';
import { MenuProps as CommonMenuProps } from './utils';

// * Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as AvatarIcon } from '../../../assets/icons/avatarIcon.svg';
import { ReactComponent as BuildingsIcon } from '../../../assets/icons/buildingsIcon.svg';

// * Interfaces
import { FilterField } from '../filterLogic';

const AvatarContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 36,
  minWidth: 36,
  height: 36,
});

const Avatar = styled(MUIAvatar)({
  width: '30px',
  height: '30px',
  fontSize: '14px',
  color: palette.white,
  backgroundColor: palette.secondary.main,
  margin: '3px',
});

const SmallAvatar = styled(MUIAvatar)({
  position: 'absolute',
  top: '4px',
  left: '4px',
  width: 18,
  height: 18,
  fontSize: '8px',
  color: palette.white,
  backgroundColor: palette.secondary.main,
});

const SelectedTypography = styled(Typography)({
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  textTransform: 'none',
  marginRight: '4px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const SelectedCount = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  bottom: '2px',
  right: '2px',
  height: 20,
  minWidth: 20,
  backgroundColor: palette.colors.primary05,
  fontSize: '8px',
  fontWeight: 500,
  lineHeight: '10px',
  color: palette.white,
  border: '2px solid',
  borderColor: palette.white,
  borderRadius: '10px',
});

const EmptyResults = styled(Box)({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '24px',
  color: palette.colors.gray04,
  padding: '6px 12px',
});

export const CustomCheckbox = styled(Checkbox)({
  padding: 0,
  paddingRight: '6px',
  '&:hover': { backgroundColor: 'transparent' },
});

interface FiltersChoiceProps {
  data: FilterField[]
  selectedValue: FilterField[]
  // eslint-disable-next-line no-unused-vars
  handleSelect: (value: FilterField[]) => void;
  isUserIcon: boolean;
  withIcon: boolean;
  blockWidth?: number;
  showSearch?: boolean;
  // eslint-disable-next-line no-unused-vars
  getAvatarSrc?: null | ((uuid: string) => string | null);
}

const FiltersChoice = ({
  data,
  selectedValue,
  handleSelect,
  isUserIcon,
  withIcon,
  blockWidth,
  getAvatarSrc = null,
  showSearch = true,
}: FiltersChoiceProps) => {
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const { target: { value } } = event;
    const newSelectedValue = typeof value === 'string' ? value.split(',') : value;
    const selectedObjects = newSelectedValue
      .map(v => data.find(item => item.uuid === v))
      .filter(item => item !== undefined);
    handleSelect(selectedObjects as FilterField[]);
  };

  const getDataLabel = (uuid: string): string => data.find(d => d.uuid === uuid)?.label || '';

  const renderValue = (selected: string[]) => {
    if (selected.length === 0) {
      return (
        <Box display="flex" alignItems="center">
          {isUserIcon ? <AvatarIcon /> : <BuildingsIcon />}
          <SelectedTypography variant="body1" color="colors.gray06">
            Select to filter..
          </SelectedTypography>
        </Box>
      );
    }

    return (
      <Box display="flex" alignItems="center">
        {selected.length === 1 && (
          <Box display="flex" alignItems="center">
            <Avatar alt={getDataLabel(selected[0])} src={paths.home.reversed()} />
            <SelectedTypography variant="body1" color="secondary.main" ml="2px">
              {getDataLabel(selected[0])}
            </SelectedTypography>
          </Box>
        )}
        {selected.length > 1 && (
          <Box display="flex" alignItems="center">
            <Box display="flex" position="relative" width={36} height={36}>
              <SmallAvatar alt={getDataLabel(selected[0])} src={paths.home.reversed()} />
              <SelectedCount>{`+${selected.length - 1}`}</SelectedCount>
            </Box>
            <SelectedTypography variant="body1" color="secondary.main" ml="4px">
              {`${selected.length} selected`}
            </SelectedTypography>
          </Box>
        )}
      </Box>
    );
  };

  const handleSearchKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setSearchText(event.target.value);
  };

  useEffect(() => {
    if (searchText === '') {
      setFilteredData(data);
    } else {
      const filtered = data.filter(item => item.label.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, data]);

  const MenuProps = {
    ...CommonMenuProps,
    MenuListProps: {
      'aria-labelledby': 'search-select-label',
      subheader: (
        showSearch ? <FiltersSearch onChange={handleSearchChange} onKeyDown={handleSearchKeyDown} /> : null
      ),
    },
  };

  return (
    <FormControl sx={{ minWidth: blockWidth }}>
      <Select
        value={selectedValue.map(v => v.uuid)}
        onChange={handleChange}
        input={<FilterInput />}
        multiple
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={ExpandMoreIcon}
        MenuProps={MenuProps}
        renderValue={renderValue}
      >
        {filteredData.length === 0 && (
          <EmptyResults>No search results found</EmptyResults>
        )}
        {filteredData.map(d => (
          <MenuItem sx={{ padding: '6px 12px' }} key={d.uuid} value={d.uuid}>
            <CustomCheckbox checked={selectedValue.some(v => v.uuid === d.uuid)} />
            {withIcon && (
              <AvatarContainer>
                <Avatar alt={d.label} src={getAvatarSrc?.(d.uuid) || ''} />
              </AvatarContainer>
            )}
            <NameContainer variant="body1" color="colors.gray08">
              {d.label}
            </NameContainer>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FiltersChoice.defaultProps = {
  blockWidth: 70,
  showSearch: true,
  getAvatarSrc: null,
};

export default FiltersChoice;
