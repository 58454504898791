// * MUI
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// * Hooks & Utils
import palette from '../../theme/palette';
import theme from "../../theme/index"

const drawerWidth = 400;
export const MainContainer = styled(Box)({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  height: '100%',
});

export const ScrollContainer = styled(Box)({
  overflowY: 'auto',
  display: 'flex',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});


export const DialerDrawer = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  width: drawerWidth, 
  height: 'fit-content',
  maxWidth: `calc(100vw - ${drawerWidth}px )`,
  display: open ? "flex" : 'none'
}));

export const BoxTest = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
    maxWidth: `calc(100vw - ${drawerWidth}px)`,
  }),
}));