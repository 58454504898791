import { styled } from '@mui/material/styles';
import { Button, Drawer } from '@mui/material';
import palette from '../../theme/palette';

export const DialContainer = styled(Drawer)({
  width: 400,
  mx: 'auto',
  position: 'relative',
  padding: "16px 24px",
  backgroundColor: palette.colors.gray01,
  border: `1px solid`,
  borderColor: palette.colors.gray03,
  textAlign: 'center',
  '& .MuiDrawer-paper': {
    position: 'relative',
    backgroundColor: palette.colors.gray01,
  },
});

export const KeypadNumber = styled(Button)({
  width: 70,
  height: 70,
  borderRadius: '50%',
  backgroundColor: '#fff',
  color: palette.colors.gray09,
  fontSize: '2rem',
  fontWeight: '700',
  borderColor: palette.colors.gray03,
  '&:hover': {
    backgroundColor: '#e8f0fe',
    borderColor: '#d0d7de',
  },
})
