/* eslint-disable import/order, no-unused-vars */
import React, {
  useCallback, useState, useContext,
} from 'react';
import { useOutletContext } from 'react-router-dom';

// * MUI
import { Box, Typography, Divider, Stack, Accordion, AccordionSummary, } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// * Components
import AnalystDropdown from '../../components/AnalystDropdown';
import { PaddingButton } from '../../components/Button/Button';
import CallDetailsItem from '../../components/CallDetailsItem';
import ComplianceReviewer from '../../components/ComplianceReviewer';
import RadioGroupYesNo from '../../components/Radio/RadioGroupYesNo';
import TextField from '../../components/TextField/TextField';

import {
  BoxTest,
  DialerDrawer,
  MainContainer,
  ScrollContainer,
} from './styledComponents';

// * Icons
import { ReactComponent as CallSlashIcon } from '../../assets/icons/callSlashIcon.svg';
import { ReactComponent as ClaimIcon } from '../../assets/icons/claimIcon.svg';
import { ReactComponent as ProfileDeleteIcon } from '../../assets/icons/profileDeleteIcon.svg';
import { ReactComponent as TickCircleIcon } from '../../assets/icons/tickCircleIcon.svg';
import { ReactComponent as Warning2Icon } from '../../assets/icons/warning2Icon.svg';

// change it later
import AddIcCallIcon from '@mui/icons-material/AddIcCall';

// * Hooks & Utils
import { isEmpty } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { AppContext } from '../../providers/AppProvider';
import { useUser } from '../../providers/UserProvider';

import { formatDateTimeToUserTimezone, isFutureDate } from '../../utils/dateHelpers';

// * Queries & Mutations
import { ASSIGN_USER_TO_REVIEW_MUTATION, TRANSCRIPT_QUERY, UPDATE_CALL_ANALYSTS_MUTATION, ACCOUNT_ANALYSTS_QUERY } from '../../graphql/calls/calls';

// * Interfaces
import type {
  ParticipantType, CallType,
  CallTypes,
} from '../../types/Call';
import type { AnalystType } from '../../types/Call';
import CallDetailsSkeleton from '../../components/Skeletons/CallDetailsSkeleton';
import HeaderSkeleton from '../../components/Skeletons/HeaderSkeleton';
import { AvatarLarge } from '../../components/MUI/Mui.styled';
import { LayoutContextType } from '../../types/Layout';
import HeaderEmptyState from '../../components/EmptyStates/HeaderEmptyState';
import SummaryCard from '../../components/Card/SummaryCard';
import DialPad from '../../components/DialPad/DialPad';


const CallDetails = () => {
  const { call, callLoading, tabIndex, isUnclaimedTab, accountName, accountAvatarLogo, updateCallChaperoneInClaimedCalls } = useOutletContext<LayoutContextType>();

  const { user } = useUser();
  const { refetchCompletedCalls, refetchClaimedCalls, refetchUnclaimedCalls } = useContext(AppContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const callStatus = call.status.value;
  const isTranscriptCompleted = call.status.value === 'Unclaimed';

  const [callParticipants, setCallParticipants] = useState<ParticipantType[]>(call.participants);
  const [callAnalysts, setCallAnalysts] = useState<AnalystType[]>(call.callAnalysts);

  const [dialNumber, setDialNumber] = useState('')
  const [openManualDialer, setOpenManualDialer] = useState(false);

  const [compliancePreambleSharedValue, setCompliancePreambleSharedValue] = useState<boolean | undefined>(undefined);

  const [chaperonNoteTextFieldValue, setChaperonNoteTextFieldValue] = useState<string>('');

  const isCall = call.type === 'call';

  const {
    data: { accountAnalysts = [] } = { accountAnalysts: [] },
  } = useQuery<{ accountAnalysts: AnalystType[] }>(
    ACCOUNT_ANALYSTS_QUERY,
    { variables: { accountUuid: call.account.uuid } },
  );

  const [updateCallAnalysts] = useMutation(UPDATE_CALL_ANALYSTS_MUTATION);

  const handleUpdateCallAnalysts = useCallback(
    async (analysts: AnalystType[]) => {
      setCallAnalysts(analysts);
      await updateCallAnalysts({
        variables: {
          transcriptUuid: call.uuid,
          analystsUuid: analysts.map(analyst => analyst.uuid),
        },
      });
      await refetchUnclaimedCalls?.();
      await refetchClaimedCalls?.();
      await refetchCompletedCalls?.();
    },
    [
      call.uuid,
      updateCallAnalysts,
      refetchUnclaimedCalls,
      refetchClaimedCalls,
      refetchCompletedCalls,
    ],
  );

  const [assignUser] = useMutation(ASSIGN_USER_TO_REVIEW_MUTATION, {
    onCompleted: ({ assignUserToReview }: {
      assignUserToReview: {
        message: string
        reviewer: CallType['review']['reviewer']
      }
    }) => {
      refetchCompletedCalls?.();
      refetchClaimedCalls?.();
      toast.success(assignUserToReview.message);

      updateCallChaperoneInClaimedCalls?.(call.uuid, assignUserToReview.reviewer);
    },
    refetchQueries: [
      { query: TRANSCRIPT_QUERY, variables: { uuid: call.uuid } },
    ],
  });

  const handleCompliancePreambleSharedChange = (value: boolean | undefined) => {
    console.log(`Changed compliancePreambleSharedValue to ${value}`);
    setCompliancePreambleSharedValue(value);
  };

  const handleChaperonNoteTextFieldValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    console.log(`Changed compliancePreambleSharedValue to ${value}`);
    setChaperonNoteTextFieldValue(value);
  };

  const handleScroll = () => {
    const headerHeight = 160;
    const anchor = document.getElementById('PlayerAnchor') as HTMLElement;
    const staticPlayer = document.getElementById('StaticAudioPlayer') as HTMLElement;
    const stickyPlayer = document.getElementById('StickyAudioPlayer') as HTMLElement;
    let playerShouldSticky;
    if (anchor && staticPlayer && stickyPlayer) {
      playerShouldSticky = anchor.getBoundingClientRect().top < headerHeight;
      if (playerShouldSticky) {
        staticPlayer.style.display = 'none';
        stickyPlayer.style.display = 'flex';
      } else {
        staticPlayer.style.display = 'flex';
        stickyPlayer.style.display = 'none';
      }
    }
  };

  const typeLabel: Record<CallTypes, string> = {
    meeting: 'Meeting',
    call: 'Expert Call',
  };

  const handleDialInput = (value: string) => {
    setDialNumber((prev) => prev + value);
  };

  const handleKeypadPress = (event: KeyboardEvent) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '#', '+'];
    if (allowedKeys.includes(event.key)) {
      setDialNumber((prev) => prev + event.key);
    } else if (event.key === 'Backspace') {
      setDialNumber((prev) => prev.slice(0, -1));
    }
  };

  const toggleManualDialer = () => {
    setOpenManualDialer(!openManualDialer);
  };


  return (
    <MainContainer>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '22px 22px',
        }}
      >
        {!!call.uuid && !callLoading && (
          <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow={1}>
            <Box mr="8px">
              <Typography
                variant={'h4'}
                color="colors.gray10"
                sx={{ marginBottom: "5px" }}
              >
                {!!call && call.name}
              </Typography>

              {tabIndex !== 0 && (
                <Typography variant="body1" color="colors.gray06" sx={{ fontSize: '14px', lineHeight: '22px' }}>
                  {call?.type === 'meeting' ? 'Meeting date:' : 'Call date:'}
                  &nbsp;
                  {formatDateTimeToUserTimezone(call?.datetime, user.timezone)}
                </Typography>
              )}
            </Box>
            {tabIndex === 0 && (
              <Box display="flex" alignItems="center">
                {!!call && call?.status?.value === 'Unclaimed' && (
                  <PaddingButton customVariant="contained-blue" startIcon={<ClaimIcon />}>
                    Claim
                  </PaddingButton>
                )}
              </Box>
            )}
            {tabIndex === 1 && (
              <Box display="flex" alignItems="center">
                {!!call && call?.status?.value === 'Claimed' && (
                  <Stack direction="row" spacing={2}>
                    <PaddingButton customVariant="contained-blue" startIcon={<AddIcCallIcon />} onClick={toggleManualDialer}>
                      Dial In
                    </PaddingButton>
                    <PaddingButton customVariant="outlined-yellow" startIcon={<ProfileDeleteIcon />} onClick={() => setOpenManualDialer(false)}>
                      Unclaim
                    </PaddingButton>
                    {/*
                      <PaddingButton customVariant="outlined-red" startIcon={<CallSlashIcon />} onClick={toggleManualDialer}>
                        Hang up
                      </PaddingButton>
                      <PaddingButton customVariant="contained-yellow" startIcon={<Warning2Icon />} onClick={toggleManualDialer}>
                        Escalate
                      </PaddingButton>
                      <PaddingButton customVariant="contained-blue" startIcon={<TickCircleIcon />} onClick={toggleManualDialer}>
                        Complete
                      </PaddingButton>
                    */}
                  </Stack>
                )}
              </Box>
            )}
          </Box>
        )}
        {!call.uuid && <HeaderEmptyState />}
        {callLoading && <HeaderSkeleton />}
      </Box>
      
      <Box sx={{ padding: '0 12px' }}>
        <Divider flexItem sx={{ margin: "0 12px", borderColor: 'colors.gray03' }} />
      </Box>

      {tabIndex === 1 && (
        <Box>
          <Box sx={{display: 'flex', alignItems: 'center', padding: '15px 22px'}}>
            <RadioGroupYesNo
              formLabel="Compliance Preamble Shared:"
              value={compliancePreambleSharedValue}
              onChange={handleCompliancePreambleSharedChange}
            />
          </Box>
          <Box sx={{ padding: '0 12px' }}>
            <Divider flexItem sx={{ margin: "0 12px", borderColor: 'colors.gray03' }} />
          </Box>
        </Box>
      )}

      <Box sx={{display: 'flex', flexDirection: 'row-reverse'}}>
        <DialerDrawer open={openManualDialer}> 
          <DialPad meetingId='23456' meetingPassword='123234' dialNumber={dialNumber} handleDialInput={handleDialInput} handleKeypadPress={handleKeypadPress} openDialer={openManualDialer} toggleManualDialer={toggleManualDialer} />
        </DialerDrawer>
        
        <BoxTest id="main_container">
          {tabIndex === 1 && (
            <Box sx={{ width: '100%', margin: '0 auto', padding: '20px' }}>
              <TextField
                placeholder="Add your comment here.."
                onChange={handleChaperonNoteTextFieldValueChange}
                rows={6}
                title="Notes"
                iconName="altNotes"
              />
            </Box>
          )}

          {(tabIndex === 0 || isEmpty(call.review?.reviewer))
            && isFutureDate(call.datetime) && !call.inSample &&

            <Box display="flex" height="calc(100vh - 168px)">
              <Box id="CallDetailsColumn" width="100%" maxWidth="100%">

                <CallDetailsSkeleton />

              </Box>
            </Box>
          }

          <ScrollContainer
            onScroll={handleScroll}
            id="scrollableRightColumn"
            sx={{ padding: isMobile ? '12px 8px' : '0px 0px 12px 20px' }}
          >
            <Accordion sx={{ paddingRight: '12px', boxShadow: "none" }} defaultExpanded>
              <AccordionSummary sx={{ padding: "0px", margin: "0px" }} expandIcon={<ExpandMoreIcon />}>

                <Typography
                  variant={'h5'}
                  color="colors.gray10"
                  m={'8px 0'}
                >
                  {`${typeLabel[call.type]} Details`}
                </Typography>
                {!isUnclaimedTab && (
                  <Divider flexItem sx={{ margin: '12px 0', borderColor: 'colors.gray03' }} />
                )}
              </AccordionSummary>

              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <SummaryCard title='Topic Summary' content={call.summary} />
                <SummaryCard title='Expert Summary' content={call.expertSummary} />
              </Stack>

              <Box mt={3}>

                <Grid container spacing={2}>
                  <Grid size={{ xs: 6, md: 6 }}>
                    <CallDetailsItem title="Call date" sx={{ marginRight: '120px', marginBottom: '12px' }}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="overline"
                          color="colors.gray08"
                          sx={{ textTransform: 'none' }}
                        >
                          {formatDateTimeToUserTimezone(call.datetime, user.timezone)}
                        </Typography>
                      </Box>
                    </CallDetailsItem>        </Grid>
                  <Grid size={{ xs: 6, md: 6 }}>

                    <CallDetailsItem title="Priority" isTypography>
                      {call.priority || 'Not Available'}
                    </CallDetailsItem>        </Grid>

                </Grid>

                {call.type === 'call' && (
                  <>
                    <Grid container spacing={2}>
                      <Grid size={{ xs: 6, md: 6 }}>
                        <CallDetailsItem title="Expert Name" isTypography>
                          {call.expertName || 'Not Available'}
                        </CallDetailsItem>            </Grid>
                      <Grid size={{ xs: 6, md: 6 }}>

                        <CallDetailsItem title="Expert Perspective" isTypography>
                          {call.expertPerspective || 'Not Available'}
                        </CallDetailsItem>            </Grid>

                    </Grid>

                  </>
                )}
              </Box>

              <Grid size={{ xs: 12 }} mt={3}>
                <CallDetailsItem title="Meeting Participants">
                  <Box display="flex" flexWrap="wrap" mt="8px">
                    {Array.isArray(call.meetingParticipants) && call.meetingParticipants.length > 0 ? (
                      [...call.meetingParticipants]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((participant, index) => (
                          <Box key={participant.email} display="flex" flexWrap="nowrap" mr="4px">
                            <Typography
                              variant="subtitle1"
                              color="colors.gray08"
                              mr="3px"
                            >
                              {`${participant.name} |`}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="colors.gray08"
                              sx={{ fontWeight: 400 }}
                            >
                              {participant.email}
                              {index < call.meetingParticipants.length - 1 && ','}
                            </Typography>
                          </Box>
                        ))
                    ) : (
                      <Typography variant="subtitle1" color="colors.gray08">
                        Not available
                      </Typography>
                    )}
                  </Box>
                </CallDetailsItem>
              </Grid>

              <Grid container spacing={2} mt={3}>
                <Grid size={{ xs: 6, md: 6 }}>
                  <CallDetailsItem title="Compliance Reviewer">
                    <ComplianceReviewer
                      assignUser={assignUser}
                      instanceUuid={call.review.uuid}
                      initialAssignee={call.review.reviewer}
                      signedInUserUuid={user.uuid}
                      placeholder="Unassigned"
                      disabled={call.review.status.value === 'Reviewed'}
                    />
                  </CallDetailsItem>
                </Grid>
                
                <Grid size={{ xs: 6, md: 6 }}>
                  <CallDetailsItem title="Analyst(s)">
                    {call.review.status.value === 'claimed' ? (
                      <Box display="flex" alignItems="center" minHeight={36}>
                        <Typography variant="subtitle2" color="colors.gray08" sx={{ fontSize: '16px', textTransform: 'none' }}>
                          {Array.isArray(callAnalysts) && callAnalysts.length > 0 ? (
                            [...call.callAnalysts]
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((analyst, index, array) => (
                                <React.Fragment key={analyst.email}>
                                  {analyst.name}
                                  {index < array.length - 1 && ', '}
                                </React.Fragment>
                              ))
                          ) : (
                            'Not Available'
                          )}
                        </Typography>
                      </Box>
                    ) : (
                      <AnalystDropdown
                        handleUpdateCallAnalysts={handleUpdateCallAnalysts}
                        accountAnalysts={callAnalysts}
                        callAnalysts={callAnalysts}
                      />
                    )}
                  </CallDetailsItem>
                </Grid>
              </Grid>

              <Grid size={{ xs: 12 }}>
                <CallDetailsItem title="Employment History">
                  {call.expertEmploymentHistory && call.expertEmploymentHistory.length > 0 ? (
                    call.expertEmploymentHistory.map((history, index) => (
                      <Typography
                        key={`call-employment-history-${index}`}
                        variant="body1"
                        color="colors.gray08"
                        sx={{ marginLeft: '28px', fontWeight: 400, whiteSpace: 'break-spaces', display: 'list-item' }}
                      >
                        {history}
                      </Typography>
                    ))
                  ) : (
                    <Typography
                      variant="body1"
                      color="colors.gray08"
                      sx={{ marginLeft: '28px', fontWeight: 400 }}
                    >
                      Not Available
                    </Typography>
                  )}
                </CallDetailsItem>
              </Grid>

              <Grid size={{ xs: 12 }}>
                <CallDetailsItem title="Expert Consulting Summary">
                  {call.expertConsultingSummary && call.expertConsultingSummary.length > 0 ? (
                    call.expertConsultingSummary.map((summary, index) => (
                      <Typography
                        key={`call-consulting-summary-${index}`}
                        variant="body1"
                        color="colors.gray08"
                        sx={{ marginLeft: '28px', fontWeight: 400, whiteSpace: 'break-spaces', display: 'list-item' }}
                      >
                        {summary}
                      </Typography>
                    ))
                  ) : (
                    <Typography
                      variant="body1"
                      color="colors.gray08"
                      sx={{ marginLeft: '28px', fontWeight: 400 }}
                    >
                      Not Available
                    </Typography>
                  )}
                </CallDetailsItem>
              </Grid>

            </Accordion>


          </ScrollContainer>

        </BoxTest>
      </Box>
    </MainContainer >
  );
};

CallDetails.defaultProps = {
  updateCallChaperoneInClaimedCalls: undefined,
};

export default CallDetails;
