import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Skeleton } from '@mui/material';

const CallListSkeleton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const keys = Array.from(Array(5).keys());

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {keys.map(item => (
        <Skeleton
          sx={{
            background: 'linear-gradient(90.12deg, rgba(202, 202, 202, 0.3) -0.85%, rgba(157, 180, 203, 0) 100.45%)',
            borderRadius: '12px',
            marginBottom: '12px',
          }}
          variant="rectangular"
          width={isMobile ? '100%' : 394}
          height={139}
          key={item}
        />
      ))}
    </Box>
  );
};

export default CallListSkeleton;
