import { gql } from '@apollo/client';

export const CALLS_QUERY = gql`
  query transcript($uuid: UUID!) {
    transcript(uuid: $uuid) {
      ...TranscriptFragment
    }
  }
`;

export const ALL_CALLS_QUERY = gql`
  query allTranscripts($uuid: UUID!) {
    transcript(uuid: $uuid) {
      ...TranscriptFragment
    }
  }
`;

export const ALL_COMPLIANCE_REVIEWERS_QUERY = gql`
    query allComplianceReviewers {
      allComplianceReviewers {
        totalCount
        results {
          id
          uuid
          isStaff
          isSuperuser
          email
          firstName
          lastName
        }
    }
  }
`;

export const UPDATE_PARTICIPANTS_MUTATION = gql`
  mutation updateParticipants($updates: [ParticipantUpdateInput!]!) {
    updateParticipants(updates: $updates) {
      status
      message
    }
  }
`;

export const ADD_CALL_TO_SAMPLE_MUTATION = gql`
  mutation addCallToSample($transcriptUuid: String!) {
    addCallToSample(transcriptUuid: $transcriptUuid) {
      status
      message
    }
  }
`;

export const ACCOUNT_ANALYSTS_QUERY = gql`
  query accountAnalysts ($accountUuid: UUID!) {
    accountAnalysts(accountUuid: $accountUuid) {
      uuid
      name
      email
      ignore
    }
  }
`;


export const TRANSCRIPT_QUERY = gql`
  query transcript($uuid: UUID!) {
    transcript(uuid: $uuid) {
      ...TranscriptFragment
    }
  }
`;

export const SUSPEND_CALL_MUTATION = gql`
  mutation suspendCall($transcriptUuid: String!) {
    suspendCall(transcriptUuid: $transcriptUuid) {
      status
      message
    }
  }
`;

export const RESUME_CALL_MUTATION = gql`
  mutation resumeCall($transcriptUuid: String!) {
    resumeCall(transcriptUuid: $transcriptUuid) {
      status
      message
    }
  }
`;

export const UPDATE_CALL_ANALYSTS_MUTATION = gql`
  mutation updateCallAnalysts($transcriptUuid: UUID!, $analystsUuid: [UUID]!) {
    updateCallAnalysts(transcriptUuid: $transcriptUuid, analystsUuid: $analystsUuid) {
      status
      message
    }
  }
`;

export const ASSIGN_USER_TO_REVIEW_MUTATION = gql`
  mutation assignUserToReview($instanceUuid: UUID!, $userUuid: UUID) {
    assignUserToReview(instanceUuid: $instanceUuid, userUuid: $userUuid) {
      status
      message
      reviewer {
        id
        uuid
        firstName
        lastName
      }
    }
  }
`;