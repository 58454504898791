import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';

import { setContext } from '@apollo/client/link/context';


const httpLink = createHttpLink({
  uri: "REACT_APP_APOLLO_CLIENT_URI", 
});


const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token') || '';

  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
