import React, { memo } from 'react';

// * Components
import CallItemCard from '../Card/CallItemCard';

// * Interfaces
import type { CallType } from '../../types/Call';

type CallListProps = {
  calls: CallType[];
  onSelect: (uuid: string) => void;
  selectedCallId?: string;
  isResearchAnalyst?: boolean;
  openDrawerMobile?: boolean;
  setOpenDrawerMobile?: React.Dispatch<React.SetStateAction<boolean>>;
};

const CallList: React.FC<CallListProps> = memo(({
  calls,
  onSelect,
  selectedCallId = undefined,
  openDrawerMobile = false,
  setOpenDrawerMobile = () => {},
}) => (
  <>
    {calls.map(
      ({
        uuid,
        account: { name: accountName, settings: { avatarLogo: accountAvatarLogo } },
        review,
        name: title,
        datetime,
        network: { value: network },
        transcript,
        expertName,
        analystName,
        review: { status: { value: reviewStatus } },
        inSample: isInSample,
        type,
        createdByResearchAnalyst,
        
      }) => (
        <CallItemCard
          key={uuid}
          uuid={uuid}
          accountName={accountName}
          accountAvatarLogo={accountAvatarLogo}
          review={review}
          title={title}
          datetime={datetime}
          network={network}
          transcriptionStatus={transcript?.status.value}
          onClick={() => onSelect(uuid)}
          openDrawerMobile={openDrawerMobile}
          setOpenDrawerMobile={setOpenDrawerMobile}
          selected={uuid === selectedCallId}
          expertName={expertName}
          analystName={analystName}
          reviewStatus={reviewStatus}
          isInSample={isInSample}
          type={type}
          createdByResearchAnalyst={createdByResearchAnalyst}
        />
      )
    )}
  </>
));

export default CallList;
