import React, { memo, useContext, useEffect, useCallback } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NoteAddIcon } from '../../assets/icons/noteAddIcon.svg';
import { ReactComponent as NoteRemoveIcon } from '../../assets/icons/noteRemoveIcon.svg';
import palette from '../../theme/palette';
import {
  CallItem, CallTitle, HighlightDot, HighlightStatus, NetworkLabel, NetworkValue, UnclaimedCardStatus,
} from './styledComponents';
import { formatTime } from '../../utils/dateHelpers';
import { AppContext } from '../../providers/AppProvider';
import paths from '../../routes/paths';

// * Interfaces
import type { CallTypes } from '../../types/Call';

const statusList = [
  { status: 'Low', color: 'colors.success04' },
  { status: 'Medium', color: 'colors.warning04' },
  { status: 'High', color: 'colors.error03' },
];

type UnclaimedCallItemCardProps = {
  uuid: string;
  accountName: string;
  accountAvatarLogo: string;
  status: string;
  title: string;
  datetime: string;
  isInSample: boolean;
  network: string;
  selected: boolean;
  expertName?: string;
  analystName?: string;
  type: CallTypes;
  onClick: React.Dispatch<React.SetStateAction<string | undefined>>;
  openDrawerMobile?: boolean;
  setOpenDrawerMobile?: React.Dispatch<React.SetStateAction<boolean>>;
};

const UnclaimedCallItemCard: React.FC<UnclaimedCallItemCardProps> = memo(({
  uuid,
  status,
  title,
  datetime,
  isInSample,
  network,
  selected,
  expertName = 'Not Available',
  analystName = 'Not Available',
  type,
  onClick,
  openDrawerMobile = false,
  setOpenDrawerMobile = () => {},
}) => {
  const navigate = useNavigate();
  const { refetchUnclaimedCalls } = useContext(AppContext);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().toISOString().slice(0, 16);
      const dateToCompare = datetime.slice(0, 16);
      if (now === dateToCompare && refetchUnclaimedCalls) {
        refetchUnclaimedCalls();
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [datetime, refetchUnclaimedCalls]);

  const handleSelectCall = useCallback(() => {
    onClick(uuid);
    setOpenDrawerMobile(!openDrawerMobile);
    navigate(paths.details.reverse({ callId: uuid }));
  }, [uuid, onClick, openDrawerMobile, setOpenDrawerMobile, navigate]);

  return (
    <CallItem onClick={handleSelectCall} className={selected ? 'selected-call-item' : ''}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography
            variant="body1"
            color="colors.gray06"
            sx={{ fontSize: '12px', lineHeight: '18px' }}
          >
            {formatTime(datetime)}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          color="colors.gray06"
          display={type === 'meeting' && network === 'Default' ? 'none' : 'flex'}
          sx={{ fontSize: '12px', lineHeight: '18px' }}
        >
          {network}
        </Typography>
      </Box>
      
      <Divider flexItem sx={{ margin: '8px 0', borderColor: 'colors.gray03' }} />

      <CallTitle
        variant="body2"
        color="colors.gray10"
        sx={{ textDecorationLine: status === 'Canceled' ? 'line-through' : 'none' }}
      >
        {title}
      </CallTitle>

      {type === 'call' && <Divider flexItem sx={{ marginBottom: '10px', borderColor: 'colors.gray03' }} />}
      
      <Box display="flex" alignItems="center">
        {type === 'call' && (
          <>
            <Box display="flex" flexDirection="column" width="50%" mr="2px">
              <NetworkLabel variant="body1" color="colors.gray08">
                Expert Name
              </NetworkLabel>
              <NetworkValue variant="body1" color="colors.gray08">
                {expertName}
              </NetworkValue>
            </Box>
            <Box display="flex" flexDirection="column" width="50%" ml="2px">
              <NetworkLabel variant="body1" color="colors.gray08">
                Analyst
              </NetworkLabel>
              <NetworkValue variant="body1" color="colors.gray08">
                {analystName}
              </NetworkValue>
            </Box>
          </>
        )}
      </Box>

    </CallItem>
  );
});

export default UnclaimedCallItemCard;
