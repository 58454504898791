import React from 'react';
import { Typography, Box } from '@mui/material';
import { StyledTextField } from './TextField.styled';
import { ReactComponent as AltNotesIcon } from '../../assets/icons/altNotesIcon.svg';
import { ReactComponent as CallCallingIcon } from '../../assets/icons/callCallingIcon.svg';

/**
 * @interface TextFieldProps
 * @property {string} [placeholder] - The placeholder text for the input field.
 * @property {string} [value] - The value of the input field (controlled input).
 * @property {(event: React.ChangeEvent<HTMLInputElement>) => void} [onChange] - The callback function to update the parent state with the input value.
 * @property {number} [rows] - The number of rows for the TextField (for multiline control).
 * @property {string} [title] - The title text to be displayed.
 * @property {string} [iconName] - The icon to display.
 * @property {boolean} [disabled] - If true, the field is read-only.
 */
interface TextFieldProps {
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rows?: number;
  title?: string;
  iconName?: 'altNotes' | 'callCalling';
  disabled?: boolean;
}

// Maps iconName to icon components, avoiding duplication
const iconMap = {
  altNotes: <AltNotesIcon />,
  callCalling: <CallCallingIcon />,
};

const TextField: React.FC<TextFieldProps> = ({
  placeholder,
  value,
  onChange,
  rows,
  title,
  iconName,
  disabled = false,
}) => (
  <Box display="flex" flexDirection="column" width="100%">
    <Box display="flex" alignItems="center">
      {iconName && <Box ml={1}>{iconMap[iconName]}</Box>}
      {title && (
        <Typography variant="subtitle1" color="colors.gray10" ml={1}>
          {title}
        </Typography>
      )}
    </Box>
    <StyledTextField
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      multiline
      rows={rows}
      disabled={disabled}
    />
  </Box>
);

export default TextField;
