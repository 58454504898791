/* eslint-disable import/order, no-unused-vars */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';

// * MUI
import { Box, Tab } from '@mui/material';

// * Components
import HeaderBar from '../../components/HeaderBar';
import { AppContext, CallTabType } from '../../providers/AppProvider';
import UnclaimedCallList from '../../components/CallList/UnclaimedCallList';
import CallList from '../../components/CallList/CallList';
import CallListSkeleton from '../../components/Skeletons/CallListSkeleton';
import HeaderSkeleton from '../../components/Skeletons/HeaderSkeleton';
import HighlightsSkeleton from '../../components/Skeletons/HighlightsSkeleton';
import TabPanel from '../../components/Tabs/TabPanel';
import TabEmptyState from '../../components/EmptyStates/TabEmptyState';
import HeaderEmptyState from '../../components/EmptyStates/HeaderEmptyState';
import CallNotFound from '../../components/CallNotFound';
import {
  Main,
  DrawerContainer,
  ScrollContainer,
  DrawerButton,
  DrawerTabs,
  VerticalDivider,
  a11yProps,
} from './components/styledComponents';
import DrawerHeader from './components/DrawerHeader';
import FiltersSection from './components/FiltersSection';

// * Icons
import { ReactComponent as DrawerIcon } from '../../assets/icons/draverIcon.svg';

// * Hooks & Utils
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import paths from '../../routes/paths';
import { handleResizeWindow, calculateWidth } from '../../utils/calculateWidth';
import useFilterLogic from './filterLogic';

// * Queries
import { ALL_CALLS_QUERY, CALLS_QUERY } from '../../graphql/calls/calls';

// * Interfaces
import type {
  CallsListType, CallType,
} from '../../types/Call';
import { LayoutContextType } from '../../types/Layout';
import { unclaimedCallObject, claimedCallObject, completedCallObject } from '../../types/mock';

window.addEventListener('resize', handleResizeWindow);

const tabs = ['unclaimed', 'claimed', 'completed'];
const QUERY_INITIAL_VALUE = { results: [claimedCallObject], totalCount: 1 };

type FetchMoreFunction = (options: {
  variables: {
    skip: number
  }
  updateQuery: (
    prev: CallsListType,
    { fetchMoreResult }: { fetchMoreResult: CallsListType }
  ) => CallsListType
}) => void

const MainLayout = () => {
  const { callId: callUuid } = useParams<{ callId?: string | undefined }>();
  const [callId, setcallId] = useState<string | undefined>(callUuid);
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const [tabIndex, setSelectedTabIndex] = useState(1);

  const [searchQuery, setSearchQuery] = useState('');
  const filterLogic = useFilterLogic();

  const toggleDrawer = () => {
    setOpen(!open);
    setTimeout(() => {
      calculateWidth();
    }, 500);
  };

  const [getCall, {
    data: { call = {} } = {},
    loading: callLoading,
    refetch: refetchCall,
    error: isNotFound,
  }] = useLazyQuery(CALLS_QUERY, { fetchPolicy: 'network-only' });

  const callWithType: CallType = call;


  const {
    data: { allCalls: unclaimedCalls = { results: [unclaimedCallObject], totalCount: 1 } } = {},
    loading: unclaimedLoading,
    fetchMore: fetchMoreUnclaimed,
    refetch: refetchUnclaimedTab,
  } = useQuery<CallsListType>(ALL_CALLS_QUERY, {
    variables: {
      searchQuery,
      networks: filterLogic.networks.map(n => n.uuid),
      scheduledTab: true,
      dateRangeStart: filterLogic.dateRangeStart,
      dateRangeEnd: filterLogic.dateRangeEnd,
      first: 10,
      skip: 0,
    },
  });

  const {
    // change this back to QUERY_INITIAL_VALUE
    data: { allCalls: completedCalls = { results: [completedCallObject], totalCount: 0 } } = {},
    loading: completedLoading,
    fetchMore: fetchMoreCompleted,
    refetch: refetchCompletedTab,
  } = useQuery<CallsListType>(ALL_CALLS_QUERY, {
    variables: {
      searchQuery,
      networks: filterLogic.networks.map(n => n.uuid),
      reviewStatus: 'completed',
      dateRangeStart: filterLogic.dateRangeStart,
      dateRangeEnd: filterLogic.dateRangeEnd,
      first: 10,
      skip: 0,
    },
    fetchPolicy: 'network-only',
  });

  const [claimedCalls, setClaimedCalls] = useState<{
    totalCount: number
    results: CallType[]
  }>(QUERY_INITIAL_VALUE);

  const {
    loading: claimedLoading,
    refetch: refetchClaimedTab,
    fetchMore,
  } = useQuery<CallsListType>(ALL_CALLS_QUERY, {
    variables: {
      searchQuery,
      networks: filterLogic.networks.map(n => n.uuid),
      completedTab: true,
      dateRangeStart: filterLogic.dateRangeStart,
      dateRangeEnd: filterLogic.dateRangeEnd,
      first: 10,
      skip: 0,
    },
    onCompleted: data => setClaimedCalls(data.allCalls),
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [hasMoreClaimed, setHasMoreClaimed] = useState(false);
  const [hasMoreUnclaimed, setHasMoreUnclaimed] = useState(false);
  const [hasMoreCompleted, setHasMoreCompleted] = useState(false);

  useEffect(() => {
    setHasMoreUnclaimed(unclaimedCalls.results.length < unclaimedCalls.totalCount);
    setHasMoreCompleted(completedCalls.results.length < completedCalls.totalCount);
    setHasMoreClaimed(claimedCalls.results.length < claimedCalls.totalCount);
  }, [
    claimedCalls.results,
    claimedCalls.totalCount,
    completedCalls.results,
    completedCalls.totalCount,
    unclaimedCalls.results,
    unclaimedCalls.totalCount,
  ]);

  const updateCallChaperoneInClaimedCalls = (
    updatedCallUuid: CallType['uuid'],
    updatedReviewer: CallType['review']['reviewer'],
  ) => {
    const updatedCalls = claimedCalls.results.map(call => {
      if (call.uuid === updatedCallUuid) {
        return {
          ...call,
          call: {
            ...call,
            review: {
              ...call.review,
              reviewer: updatedReviewer,
            },
          },
        };
      }
      return call;
    });

    setClaimedCalls({ ...completedCalls, results: updatedCalls });
  };


  const loadMoreCalls = (
    fetchMoreFunction: FetchMoreFunction,
    hasMoreFlag: boolean,
    allCalls: CallsListType['allCalls'],
  ) => {
    if (!hasMoreFlag) return;

    fetchMoreFunction({
      variables: { skip: allCalls.results.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const prevResults = prev.allCalls.results;
        const newResults = fetchMoreResult.allCalls.results;

        const combinedResults = [
          ...prevResults,
          ...newResults.filter(
            (newResult: { uuid: any; }) => !prevResults.some((prevResult: { uuid: any; }) => prevResult.uuid === newResult.uuid),
          ),
        ];

        return {
          allCalls: {
            ...prev.allCalls,
            results: combinedResults,
          },
        };
      },
    });
  };

  const loadMoreClaimed = () => {
    loadMoreCalls(fetchMore, hasMoreClaimed, claimedCalls);
  };

  const loadMoreUnclaimed = () => {
    loadMoreCalls(fetchMoreUnclaimed, hasMoreUnclaimed, unclaimedCalls);
  };

  const loadMoreCompleted = () => {
    loadMoreCalls(fetchMoreCompleted, hasMoreCompleted, completedCalls);
  };

  const {
    setRefetchClaimedCalls,
    setRefetchCompletedCalls,
    setRefetchUnclaimedCalls,
    setRefetchCall,
    setCallsSelectedTabName,
  } = useContext(AppContext);

  useEffect(() => {
    setRefetchClaimedCalls(refetchClaimedTab);
    setRefetchCompletedCalls(refetchCompletedTab);
    setRefetchUnclaimedCalls(refetchUnclaimedTab);
    setRefetchCall(refetchCall);
  }, [
    setRefetchClaimedCalls,
    setRefetchCompletedCalls,
    setRefetchUnclaimedCalls,
    refetchCompletedTab,
    refetchClaimedTab,
    refetchUnclaimedTab,
    setRefetchCall,
    refetchCall,
  ]);

  const statusToExclude = ['Scheduled', 'Created', 'In Progress', 'Canceled'];

  // autoselect tab where calls should be
  useEffect(() => {
    calculateWidth();
    if (!callLoading && !isEmpty(callWithType)) {
      if (callWithType.review.status.key === 'completed') {
        setSelectedTabIndex(2);
      } else if (callWithType.review.status.key !== 'completed'
        && callWithType.status.value !== 'Suspended'
          && !statusToExclude.includes(callWithType.status.value)) {
        setSelectedTabIndex(1);
      } else {
        setSelectedTabIndex(0);
      }
    }
    // eslint-disable-next-line
  }, [callWithType, callLoading]);

  useEffect(() => {
    setCallsSelectedTabName(tabs[tabIndex] as CallTabType);
    calculateWidth();
    // eslint-disable-next-line
  }, [tabIndex]);

  // get call if call uuid changed or select first if there is no call in URL
  useEffect(() => {
    if ((callId && !call) || (call && callId && call.uuid !== callId)) {
      // getCall({
      //   variables: {
      //     id: callId,
      //   },
      // });
    }
    if (!callUuid && claimedCalls.results.length > 0) {
      navigate(paths.details.reversed({ callId: claimedCalls.results[0].uuid }));
      setcallId(claimedCalls.results[0].uuid);
    }
    // eslint-disable-next-line
  }, [callId, callUuid, claimedCalls.results, completedCalls.results]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);

    const handleNavigation = (calls: CallType[], loading: boolean) => {
      if (loading) return;

      if (calls.length === 0) {
        navigate(paths.details.reversed({ callId: 'not-found' }));
        setcallId('not-found');
        return;
      }

      navigate(paths.details.reversed({ callId: calls[0]?.uuid }));
      setcallId(calls[0]?.uuid);
    };

    switch (newValue) {
      case 0:
        handleNavigation(unclaimedCalls.results, unclaimedLoading);
        break;
      case 1:
        handleNavigation(claimedCalls.results, claimedLoading);
        break;
      case 2:
        handleNavigation(completedCalls.results, completedLoading);
        break;
      default:
        break;
    }
  };

  const handleOnSearch = useCallback((search: string) => {
    setSearchQuery(search);
  }, []);

  const handleOnBack = async () => {
    setSearchQuery('');
  };
  
  const object_to_render: Record<string, CallType> = {
    "123e4567-e89b-12d3-a456-426614174000": unclaimedCallObject,
    "123e4567-e89b-12d3-a456-426614174001": claimedCallObject,
    "123e4567-e89b-12d3-a456-426614174002": completedCallObject
  }

  const contextData: LayoutContextType = {
    call: object_to_render[callId ?? "123e4567-e89b-12d3-a456-426614174000"],
    callLoading: false,
    tabIndex: tabIndex,
    isUnclaimedTab: tabIndex===0,
    accountName: unclaimedCallObject.account?.name,
    accountAvatarLogo: unclaimedCallObject.account?.settings.avatarLogo,
    updateCallChaperoneInClaimedCalls
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <HeaderBar />
      <DrawerContainer variant="persistent" anchor="left" open={open}>
        <DrawerHeader
        // change after api integration
          isNotFound={undefined}
          toggleDrawer={toggleDrawer}
          onSearch={handleOnSearch}
          onBack={handleOnBack}
          isSearching={true}
        />
        <FiltersSection {...filterLogic} tabIndex={tabIndex} />
        <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'colors.gray03' }}>
          <DrawerTabs value={tabIndex} onChange={handleTabChange} variant="fullWidth" aria-label="sidebar tabs">
            <Tab label={`Unclaimed (${unclaimedCalls.totalCount || 0})`} {...a11yProps(0)} />
            <Tab label={`Claimed (${claimedCalls.totalCount || 0})`} {...a11yProps(1)} />
            <Tab label={`Completed`} {...a11yProps(2)} />
          </DrawerTabs>
        </Box>
        <ScrollContainer id="scrollableUnclaimedTab">
          <TabPanel value={tabIndex} index={0}>
            {unclaimedLoading && <CallListSkeleton />}
            <InfiniteScroll
              // @ts-ignore
              pageStart={0}
              dataLength={unclaimedCalls.results.length}
              hasMore={hasMoreUnclaimed}
              next={loadMoreUnclaimed}

              loader={<p>Loading...</p>}
              scrollableTarget="scrollableUnclaimedTab"
            >
              <UnclaimedCallList
                calls={unclaimedCalls.results}
                onSelect={setcallId}
                selectedCallId={callId}
              />
            </InfiniteScroll>
          </TabPanel>
        </ScrollContainer>
        <ScrollContainer id="scrollableClaimedTab">
          <TabPanel value={tabIndex} index={1}>
            <InfiniteScroll
              // @ts-ignore
              pageStart={0}
              dataLength={claimedCalls.results && claimedCalls.results.length}
              hasMore={hasMoreClaimed}
              next={loadMoreClaimed}
              loader={<>...</>}
              scrollableTarget="scrollableClaimedTab"
            >
              <CallList
                calls={claimedCalls.results}
                onSelect={setcallId}
                selectedCallId={callId}
              
              />
            </InfiniteScroll>
            {claimedLoading && <CallListSkeleton />}
          </TabPanel>
        </ScrollContainer>
        <ScrollContainer id="scrollableCompletedTab">
          <TabPanel value={tabIndex} index={2}>
            <InfiniteScroll
              // @ts-ignore
              pageStart={0}
              dataLength={completedCalls.results.length}
              hasMore={hasMoreCompleted}
              next={loadMoreCompleted}

              loader={<p>Loading...</p>}
              scrollableTarget="scrollableCompletedTab"
            >
              <CallList
                calls={completedCalls.results}
                onSelect={setcallId}
                selectedCallId={callId}
              />
            </InfiniteScroll>
            {completedLoading && <CallListSkeleton />}
          </TabPanel>
        </ScrollContainer>
        {(!unclaimedLoading && !unclaimedCalls.totalCount) && (
          <TabEmptyState isVisible={tabIndex === 0} title="No Unclaimed Calls" />
        )}
        {(!claimedLoading && !claimedCalls.results.length) && (
          <TabEmptyState isVisible={tabIndex === 1} title="No Claimed Calls" />
        )}
        {(!completedLoading && !completedCalls.results.length) && (
          <TabEmptyState isVisible={tabIndex === 2} title="No Completed Calls" />
        )}
      </DrawerContainer>
      <Main open={open}>
        {false ? (
          <CallNotFound />
        ) :
          <Box display="flex" flexDirection="column">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box display="flex" sx={{ ...(open && { display: 'none' }) }}>
                <DrawerButton aria-label="open drawer" onClick={toggleDrawer}>
                  <DrawerIcon />
                </DrawerButton>
                <VerticalDivider orientation="vertical" flexItem />
              </Box>
            </Box>

            <Outlet context={contextData}/>
          </Box>
        }
      </Main>
    </Box>
  );
};

export default MainLayout;
