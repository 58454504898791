// @ts-ignore
// eslint-disable-next-line
import RoutePath from '../utils/namedUrls';

export default {    
    home: new RoutePath('/'),
    details: new RoutePath('/calls/:callId'),
    signIn: new RoutePath('/account/sign-in'),
    userSettings: new RoutePath('/account/user-settings'),
    organizationSettings: new RoutePath('/account/organization-settings'),
    testComponents: new RoutePath('/test-components'),
};
